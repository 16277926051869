import React from "react";

// Customizable Area Start
import { 
  bell, 
  partner, 
  envelope, 
  logo, 
  search, 
  activeResident,
  resident,
  mic,
  activeDiscover,
  social,
  library,
  playlist,
  dashboard,
  activeDashboard,
  activeLibrary,
  activePlaylist,
  activeSocial,
  discover,
  build,
  activeBuild,
  engage,
  activeEngage,
  activeHostSettings,
  activeMonetize,
  activeMyChannel,
  myChannel,
  hostSettings,
  monetize,
  integrations
} from "./assets";
import { Avatar, IconButton,Box ,Dialog,
  DialogContent,Button} from "@mui/material";
import { FmdGood, PersonOutline, Settings, SubdirectoryArrowRight, EventSeat, AllInbox }from '@mui/icons-material'
import { ResolveCondition, handleNavigation } from "../../utilities/src/CustomHelpers";
import { ConditionJsx } from "../../utilities/src/ConditionJsx";
import CloseIcon from '@mui/icons-material/Close';
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { activeIcon, isMenuOpen, activeSidebarIcon, activeHostSidebarIcon } = this.state
    const accountDescription = "Settings Related To Your Personal Information And Account Credentials"
    const userDescription = "Digital copywriter at Digital media Agency. I love sci-fi movies and cats."
    
    return (
      <>
      <div style={{display: "flex"}} >
        <div style={{position: "absolute", top: 0, left: 0, color: "white"}}>
        <div data-test-id="sidebar" style={styles.sidebarMain} onClick={(e) => e.stopPropagation()}>
          <div style={styles.sidebarWrapper} >
            <img src={mic} width="69px" height="70px" alt="logo" />
            <div style={{padding: "35px 0"}} >
              <Avatar
                data-test-id="avatar"
                onClick={this.handleProfilePhotoClick}
                sx={{ width: 76, height: 76, cursor: "pointer" }} 
                src={ResolveCondition(this.state.userResponse.attributes.profile_picture !== "" , this.state.userResponse.attributes.profile_picture ,"/broken-image.jpg")} />
            </div>
            <div style={styles.sidebarNavItems} >
              {this.residentMember() && 
                <>
                  <img 
                    data-test-id="dashboard"
                    src={activeSidebarIcon === 'dashboard' ? activeDashboard : dashboard}
                    onClick={() => this.setActiveSidebarIconAndRedirect('dashboard', "Dashboard")}
                    alt="dashboard icon"
                    style={{ cursor: "pointer" }} />
                  <img 
                    data-test-id="playlist"
                    src={activeSidebarIcon === 'playlist' ? activePlaylist : playlist}
                    onClick={() => this.setActiveSidebarIconAndRedirect('playlist', "Playlist")}
                    alt="playlist icon"
                    style={{ cursor: "pointer" }} />
                  <img 
                    data-test-id="library"
                    src={activeSidebarIcon === 'library' ? activeLibrary : library}
                    onClick={() => this.setActiveSidebarIconAndRedirect('library', "AudioLibrary")}
                    alt="library icon"
                    style={{ cursor: "pointer" }} />
                  <img
                    data-test-id="discover"
                    src={activeSidebarIcon === 'discover' ? activeDiscover : discover}
                    onClick={() => this.setActiveSidebarIconAndRedirect('discover', "Discover")}
                    alt="discover icon"
                    style={{ cursor: "pointer" }} />
                  <img 
                    data-test-id="social"
                    src={activeSidebarIcon === 'social' ? activeSocial : social}
                    onClick={() => this.setActiveSidebarIconAndRedirect('social', "Social")}
                    alt="social icon"
                    style={{ cursor: "pointer" }} />
                </> }
                {this.hostMember() && 
                <>
                  <img 
                    data-test-id="myChannel"
                    src={activeHostSidebarIcon === 'myChannel' ? activeMyChannel : myChannel}
                    onClick={() => this.setActiveHostSidebarIconAndRedirect('myChannel', "MyChannel")}
                    alt="myChannel icon"
                    style={{ cursor: "pointer" }} />
                  <img 
                    data-test-id="build"
                    src={activeHostSidebarIcon === 'build' ? activeBuild : build}
                    onClick={() => this.setActiveHostSidebarIconAndRedirect('build', "Build")}
                    alt="build icon"
                    style={{ cursor: "pointer" }} />
                  <img 
                    data-test-id="engage"
                    src={activeHostSidebarIcon === 'engage' ? activeEngage : engage}
                    onClick={() => this.setActiveSidebarIconAndRedirect('engage', "Engage")}
                    alt="engage icon"
                    style={{ cursor: "pointer" }} />
                  <img
                    data-test-id="monetize"
                    src={activeHostSidebarIcon === 'monetize' ? activeMonetize : monetize}
                    onClick={() => this.setActiveSidebarIconAndRedirect('monetize', "Monetize")}
                    alt="monetize icon"
                    style={{ cursor: "pointer" }} />
                  <img 
                    data-test-id="integrations"
                    src={integrations}
                    onClick={() => this.setActiveSidebarIconAndRedirect('integrations', "Integrations")}
                    alt="integrations icon"
                    style={{ cursor: "pointer" }} />
                  <img 
                    data-test-id="hostSettings"
                    src={activeHostSidebarIcon === 'hostSettings' ? activeHostSettings : hostSettings}
                    onClick={() => this.setActiveSidebarIconAndRedirect('hostSettings', "HostSettings")}
                    alt="hostSettings icon"
                    style={{ cursor: "pointer" }} />
                </>}
              </div>
            </div>
          </div>
        </div>

          <div style={styles.main}>
            <div style={{...styles.iconsWrapper, width: "50%", paddingRight: "5px"}}>
              <img
              onClick={() => this.onLogoClick()}
                style={styles.logoImg}
                src={logo} 
                alt="logo" />
              <img
                data-test-id="resident"
                src={activeIcon === 'resident' ? activeResident : resident}
                onClick={() => this.setActiveIconAndRedirect('resident', "Dashboard")}
                alt="resident icon"
                style={{ cursor: "pointer" }} />
              <img
                data-test-id="host"
                src={this.getHostIcon()}
                onClick={() => this.setActiveIconAndRedirect('host', "HostLogin")}
                alt="host icon"
                style={{ cursor: "pointer" }}/>
              <img
                data-test-id="partner"
                src={partner}
                // src={activeIcon === 'partner' && partner} // Can't find active partner icon
                onClick={() => this.setActiveIconAndRedirect('partner', "nonExistingRoute")} 
                alt="partner icon"
                style={{ cursor: "pointer" }}/>
            </div>
            <div style={{...styles.iconsWrapper, width: "50%"}}>
              <div style={styles.searchWrapper}>
                <img src={search} style={{ position: 'absolute', left: "7px", top: "8px"}} />
                <input
                  data-test-id="search"
                  value={this.state.searchValue}
                  onChange={(event) => this.handleSearchChange(event)} 
                  style={styles.searchInput}
                  type="search" 
                  placeholder="Search" />
              </div>
              <div style={styles.notifyIcons}>
                <img src={bell} />
                <div onClick={() => this.setState({ openDropdown: !this.state.openDropdown })} data-test-id='envelopIcon'>
                  <img src={envelope} />
                </div>
              </div>
            </div>
          </div>
          {isMenuOpen && (
              <div style={styles.sidebarModalOverlay} data-test-id="menu" onClick={() => this.setState({ isMenuOpen: false })}>
                <div style={styles.sidebarModal} onClick={(e) => e.stopPropagation()}>
                  <div style={styles.sidebarInfo}>
                    <Avatar 
                      sx={{ width: 132, height: 132, border: "4px solid #030303" }} 
                      src={ResolveCondition(this.state.userResponse.attributes.profile_picture !== "", this.state.userResponse.attributes.profile_picture , "/broken-image.jpg")} />
                    <div style={styles.sidebarDetails}>
                      <span style={styles.sidebarName}> {this.state.userResponse.attributes.first_name} {this.state.userResponse.attributes.last_name}</span>
                      <div style={{display: "flex", alignItems: "center", gap: 4}}>
                        <FmdGood sx={{ color: "#fff" }} fontSize="small" />
                        <span style={styles.sidebarLocation}>{this.state.userResponse.attributes.user_location}</span>
                      </div>
                    </div>
                    {ConditionJsx(this.state.userResponse.attributes.bio !== null , <div style={styles.sidebarDescription}>{this.state.userResponse.attributes.bio}</div>,<div></div>) }
                  </div>
                  <div style={styles.sidebarSettings}>
                    <span style={styles.sidebarHeader}>Settings</span>
                    <div style={{...styles.sidebarAccount, backgroundColor: ResolveCondition(this.state.settingsSideBarActiveIcon === "AccountSettings", "#030303", "#44403C"), cursor: "pointer"}} onClick={() => this.handleSettingsActiveIcon("AccountSettings")} data-test-id= "account-button">
                      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                        <PersonOutline />
                        <span style={styles.sidebarTitle}>Account</span>
                      </div>
                      <span style={styles.sidebarSettingsDescription}>{accountDescription}</span>
                    </div>
                    <div style={{...styles.sidebarAccount, backgroundColor: ResolveCondition(this.state.settingsSideBarActiveIcon === "Settings2", "#030303", "#44403C"), marginTop: '10px', cursor: "pointer"}} onClick={() => this.handleSettingsActiveIcon("Settings2")} data-test-id= "settings-button">
                      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                        <Settings />
                        <span style={styles.sidebarTitle} data-test-id="Settings" onClick={()=>{this.handleNavigation("Settings2")}}>Settings</span>
                      </div>
                      <span style={styles.sidebarSettingsDescription}>Access To Configurations</span>
                    </div>
                    <div style={{...styles.sidebarAccount, backgroundColor: this.state.settingsSideBarActiveIcon === "Logout" ? "#030303" : "#44403C"}}>
                      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                        <SubdirectoryArrowRight />
                        <span style={styles.sidebarTitle} data-test-id="SubdirectoryArrowRight" onClick={()=>this.setState({isModalOpen:true})}>Log out</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        {this.state.openDropdown && (
          <div style={{width: "100%", height: "8rem"}}>
            <p
            data-test-id="contactMayor"
              onClick={(e) => {
                const target = e.target as HTMLElement; 
                window.location.href ='https://podcasttown.zohodesk.com/portal/en/newticket';
                target.style.backgroundColor = '#FF0807';  
              }}
              style={{ cursor: 'pointer', color: '#fff', transition: 'color 0.3s', borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px", 
                backgroundColor: '#44403C',
                fontFamily: 'Nexa-Heavy', 
                border: '1px solid white', 
                padding: '1%', 
                width: '14%', 
                height: '3rem', 
                marginLeft: '81%', 
                marginTop: '-1%',
                borderBottom: 'none'
               }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLElement; 
                target.style.backgroundColor = '#FF0807'; 
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLElement;  
                target.style.backgroundColor = '#44403C';  
              }}
            >
              <IconButton><EventSeat style={{color: "#fff"}}/></IconButton> Contact The Mayor's Office
            </p>
            <p
            data-test-id="myInbox"
              onClick={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = '#FF0807'; 
                handleNavigation('MyInbox', this.props) 
              }}
              style={{ cursor: 'pointer', color: '#fff', transition: 'color 0.3s', borderBottomRightRadius: "8px",
                borderBottomLeftRadius: "8px", 
                backgroundColor: '#44403C',
                fontFamily: 'Nexa-Heavy', 
                border: '1px solid white', 
                padding: '1%', 
                width: '14%', 
                height: '2rem', 
                marginLeft: '81%', 
                marginTop: '-2%',
                borderTop: 'none'
               }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLElement;  
                target.style.backgroundColor = '#FF0807';  
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLElement;  
                target.style.backgroundColor = '#44403C'; 
              }}
            ><IconButton><AllInbox style={{color: "#fff"}}/></IconButton>My Inbox</p>
          </div>
        )}
          <Dialog
          open={this.state.isModalOpen}
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              borderRadius: "8px 8px 32px 8px",
              width: "550px",
              height: "250px",
            },
          }}
        >
          <DialogContent
            style={{ backgroundColor: "#44403C", color: "white", padding: 0, }}

          >
            <IconButton
              data-test-id="onCloseModal"
              id="categoriesModal"
              onClick={this.handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white'
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box id="categoriesModal" style={{paddingTop:"35px"}}>
              <h2 style={{ textAlign: "center", fontFamily: "Nexa-Heavy" }}>Are You Sure You Want To Logout?</h2>
            </Box>
            <Box style={{
              width: "498px", height: "104px", borderTop: "1px solid white", display: "flex",
              alignItems: "center", marginTop: "25px",
              justifyContent: "flex-end",
            }}>
             
                <Button style={{
                  height: "56px",
                  width: "152px",
                  borderRadius: "8px",
                  backgroundColor: "black",
                  textTransform: "capitalize",
                  color: "white",
                  fontWeight: 900,
                  fontSize: "14px",
                  margin: "10px"
                }}  onClick={this.handleCloseModal}>Cancel</Button>
                 <Button style={{
                  height: "56px",
                  width: "152px",
                  borderRadius: "8px",
                  backgroundColor: "#FF0807",
                  textTransform: "capitalize",
                  color: "white",
                  fontWeight: 900,
                  fontSize: "14px",
                 
                  margin: "10px"
                }}  onClick={()=>this.handleLogOut()}>Logout</Button>
         
            </Box>

          </DialogContent>
        </Dialog>
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const styles = {
  main: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    backgroundColor: "#030303",
    gap: "65px",
    width: '100%',
    height: "65px",
    paddingTop: "45px",
    paddingBottom: "40px",
    paddingRight: "5%",
    paddingLeft: "calc(6% + 135px)"
  },
  iconsWrapper: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center" as const,
    flex: 1,
    position: 'relative' as const
  },
  searchInput: {
    padding: "10px 8px 10px 8px",
    border: "1px solid white",
    borderRadius: "98px",
    backgroundColor: "#030303",
    color: "white",
    outline: "none",
    flex: 7,
    paddingLeft: "35px",
  },
  notifyIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  logoImg: {
    width: "148px",
    height: "65px" ,
    cursor: "pointer"
  },
  container: {
    display: "flex",
    flexDirection: "row" as const,
  },
  sidebar: {
    width: "135px", 
    backgroundColor: "#030303",
    borderRight: "1px solid #E2E8F0",
    height: "100vh",
  },
  sidebarMain: {
    backgroundColor: "#030303",
    borderRight: "1px solid #E2E8F0",
    width: "135px",
    height: "90vh"
  },
  sidebarWrapper:{
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    alignItems: "center" as const,  
    paddingTop: "45px",
  } as React.CSSProperties,
  sidebarNavItems: {
    display: "flex", 
    flexDirection: "column" as const, 
    alignItems: "center" as const, 
    gap: 20
  } as React.CSSProperties,
  sidebarModalOverlay: {
    position: 'fixed',
    top: 0,
    left: '136px',
    width: 'calc(100% - 136px)',
    height: '90vh',
    backdropFilter: 'blur(4px)' as const,
    zIndex: 100 as const,
    display: 'flex',
    justifyContent: 'start',
  } as React.CSSProperties,
  sidebarModal: {
    display: "flex",
    flexDirection: "column",
    width: "362px",
    backgroundColor: '#44403C',
    borderBottomRightRadius: "64px" as const,
    borderLeft: "1px solid #030303" as const
  } as React.CSSProperties,
  sidebarInfo: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#312F2D",
    borderBottomRightRadius: "32px",
    paddingTop: typeof window !== 'undefined' && window.innerHeight > 900 ? "50px" : "5px",
    paddingBottom: "10px"
  } as React.CSSProperties,
  sidebarDetails: {
    display: "flex",
    alignItems: 'center',
    color: "#fff",
    flexDirection: "column",
    padding: "0 20px"
  } as React.CSSProperties,
  sidebarLocation: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
  } as React.CSSProperties,
  sidebarDescription: {
    width: "327px",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 900,
    textAlign: "center"
  } as React.CSSProperties,
  sidebarName: {
    textAlign: "center",
    width: "265px",
    fontWeight: 900,
    fontSize: typeof window !== 'undefined' && window.innerHeight < 900 ? "20px" : "30px",
    fontFamily: "Poppins",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as React.CSSProperties,
  sidebarSettings: {
    display: "flex",
    flexDirection: "column",
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: 900,
  } as React.CSSProperties,
  sidebarHeader: {
    backgroundColor: "#312F2D",
    margin: "10px 0",
    padding: "10px 20px",
    fontSize: typeof window !== 'undefined' && window.innerHeight < 900 ? "16px" : "20px",
  } as React.CSSProperties,
  sidebarAccount: {
    display: "flex",
    flexDirection: "column",
    margin: "0 20px",
    padding: "15px 15px",
    borderRadius: "8px"
  } as React.CSSProperties,
  sidebarTitle: {
    fontSize: typeof window !== 'undefined' && window.innerHeight < 900 ? "15px" : "18px",
    cursor: "pointer"
  },
  sidebarSettingsDescription: {
    fontSize: "14px",
    width: "250px",
    paddingTop: "10px",
    paddingLeft: "25px"
  } as React.CSSProperties,
  mailDropdown: {
    borderRadius: '8px', 
    backgroundColor: '#44403C', 
    color: '#fff', 
    fontFamily: 'Nexa-Regular', 
    border: '2px solid white', 
    padding: '1%', 
    width: '14%', 
    height: '7rem', 
    marginLeft: '81%', 
    marginTop: '-1%'
  }
};


// Customizable Area End
