import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  TextField,
  styled,
  Box,
  Avatar,
  IconButton,
  Dialog,
  DialogContent,
  Divider,
  DialogActions
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import { Reply, Delete, Send, CloseOutlined } from '@mui/icons-material';
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { goBack, host_logo, user_logo } from "./assets";
// Customizable Area End
import ReplyToController, { Props } from "./ReplyToController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ReplyTo extends ReplyToController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu navigation={this.props.navigation} id='1' />
        <StyledWrapperSetting>
        <Box display="flex" alignItems="center" mb={3}>
          <img onClick={this.handleGoBack} src={goBack} alt="Go Back" style={{ marginBottom: '20px', width: "2%", marginLeft: "6%" }} />
          <Typography variant="h4" sx={{ color: '#fff', marginBottom: '15px', marginLeft: "15px", fontWeight: "bold" }}>
            My Inbox
          </Typography>
        </Box>
          <Box sx= {{ backgroundColor: "#44403C", paddingTop: '2%', height: "auto", width: "90%", marginLeft: '96px', marginTop: "14px", borderRadius: "8px", paddingBottom: this.state.showReplyBox || this.state.isMessageSent ? "0px" : "20px"}}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', backgroundColor: "#312F2D", padding: '10px', borderRadius: "8px", height: "6rem" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '6%' }}>
                  <Avatar alt="Ana Canal" src={host_logo} sx={{ marginRight: '10px' }} />
                  <Box>
                    <Box display="flex" flexDirection="row">
                    <Typography variant="body2" sx={{ color: '#fff',  marginBottom: '10px', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>ANA CANNAL</Typography>
                      <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', display: 'flex', alignItems: 'center', marginLeft: '8px', fontFamily: "Nexa-Regular", fontWeight: "bold" }}>
                        You forgot to send the files
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ color: '#fff', marginLeft: '0%', fontWeight: 'bold', fontFamily: "Nexa-Regular" }}>20 Hours Ago</Typography>
                  </Box>
              </Box>
              <Box sx={{ display: 'flex', marginRight: '5%' }}>
                  <Button
                      variant="contained"
                      startIcon={<Reply />}
                      sx={{ backgroundColor: '#030303', width: "111px", height: "35px", borderRadius: "8px", marginLeft: "7%", textTransform: "capitalize", '&:hover': {backgroundColor: "#030303"}, fontFamily: "Nexa-Regular", fontWeight: "bold" }}
                      onClick={() => this.handleReply()}
                      data-test-id= "reply-button"
                  >
                      Reply
                  </Button>
                  <Button
                      variant="contained"
                      startIcon={<Delete />}
                      onClick={this.handleOpenDialog}
                      data-test-id= "delete-button"
                      sx={{ backgroundColor: '#FF0807', width: "111px", height: "35px", borderRadius: "8px", marginLeft: "7%", textTransform: "capitalize", '&:hover': {backgroundColor: "#FF0807"}, fontFamily: "Nexa-Regular", fontWeight: "bold"  }}
                  >
                      Delete
                  </Button>
              </Box>
          </Box>
          <Box sx={{ marginBottom: '20px', height: 'auto' }}>
              <Typography variant="body1" sx={{ color: '#bdbdbd', fontSize: "14px", lineHeight: "26px", margin: '3% 5% 3% 5%', fontFamily: "Nexa-Regular", fontWeight: "bold" }}>
              Hi Michael,<br/>
              I hope this message finds you well. I wanted to check in regarding the files we discussed last week. I understand that things can get busy, but I haven't received them yet, and they are essential for moving forward with our project.
              <br/>
              The documents are particularly important as they contain the data and information needed for the next phase of our work. Without these files, we're at a bit of a standstill, and I'd really appreciate it if you could send them over as soon as possible.
              <br/>
              If there’s been any delay or issue in retrieving or compiling the files, please don't hesitate to let me know. I'm more than happy to assist if there's anything I can do to help expedite the process.
              <br/>
              Looking forward to your response and receiving the files.
              <br/>
              Best regards,Ana 
              </Typography>
          </Box>
          {this.state.showReplyBox && !this.state.isMessageSent && (
            <Box sx={{ padding: '10px', backgroundColor: "#312F2D", borderRadius: "8px", display: 'flex', flexDirection: 'column', height: "46/*  */%", marginBottom: "4%" }}>
              <Box display="flex" flexDirection="row" gap="8px" style= {{ marginLeft: '6%' }}>
                <Avatar alt="Ana Canal" src={user_logo} sx={{ marginRight: '10px', marginTop: "1%" }} />
                <Box display="flex" flexDirection="row">
                  <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', marginTop: "-3%", display: 'flex', alignItems: 'center', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>
                    <IconButton sx={{ padding: '0', marginRight: '4px' }}>
                      <Reply style={{ color: "white" }} />
                    </IconButton>
                    Reply to
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', marginTop: "-3%", display: 'flex', alignItems: 'center', marginLeft: '15px', fontFamily: "Nexa-Regular" }}>
                    You forgot to send the files
                  </Typography>
                </Box>
                <Typography sx={{ color: "white", marginLeft: '-20%', marginTop: "3%", textTransform: 'capitalize', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>
                  Ana Canal
                </Typography>
              </Box>
                  <TextField
                      variant="outlined"
                      multiline
                      rows={8}
                      sx={{ 
                        backgroundColor: "#030303", 
                        borderRadius: '8px', 
                        flexGrow: 1, 
                        margin: '1% 5% 3% 5%',
                        width: "80rem",
                        height: "10rem",
                        "& .MuiInputBase-inputMultiline": {
                          color: '#fff',
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "transparent",
                          },
                          "&:hover fieldset": {
                            borderColor: "transparent", 
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent", 
                          }
                        }
                      }}
                      onChange={this.handleMessageChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: "-11px", paddingRight: "72px" }}> 
                      <Button
                          variant="contained"
                          startIcon={<Send />}
                          sx={{ 
                              backgroundColor: '#FF0807', 
                              height: "35px", 
                              borderRadius: "8px",
                              textTransform: "capitalize",
                              margin: "2%",
                              '&.Mui-disabled': {
                                backgroundColor: "#B2B3B3",
                                color: "#fff"
                              },
                              '&:hover': {
                                backgroundColor: "#FF0807"
                              },
                              fontFamily: "Nexa-Heavy", 
                              fontWeight: "bold"
                          }}
                          disabled={this.state.message.trim() === ''}
                          onClick={() => this.handleSend()}
                          data-test-id= "send-button"
                      >
                          Send
                      </Button>
                  </Box>
              </Box>
          )}
          {this.state.isMessageSent && (
            <Box sx={{ marginTop: '20px', padding: '20px', backgroundColor: '#312F2D', borderRadius: '8px', position: 'relative' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box display="flex" flexDirection="row" gap="8px" sx={{ marginLeft: '6%' }}>
                  <Avatar alt="Ana Canal" src={user_logo} sx={{ marginRight: '10px' }} />
                  <Box>
                    <Box display="flex" flexDirection="row">
                      <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', display: 'flex', alignItems: 'center', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>
                        <IconButton sx={{ padding: '0', marginRight: '4px' }}>
                          <Reply style={{ color: "white" }} />
                        </IconButton>
                        Reply to
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#FFF', marginBottom: '10px', display: 'flex', alignItems: 'center', marginLeft: '8px', fontFamily: "Nexa-Regular" }}>
                        You forgot to send the files
                      </Typography>
                    </Box>
                    <Typography sx={{ color: "white", marginLeft: '12%', textTransform: 'capitalize', fontWeight: 'bold', fontFamily: "Nexa-Heavy" }}>
                      Ana Canal
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography variant="body1" sx={{ color: '#bdbdbd', fontWeight: "bold", fontSize: "14px", lineHeight: 1.6, margin: '3% 5%', fontFamily: "Nexa-Regular" }}>
                Hi Ana,<br/>
                Thank you for your patience, and I apologize for the delay in getting the files to you.
                Things have been quite hectic on my end, but I understand how important these files are for the project.
              <br/>
                I'm attaching the requested documents to this email. Please review them and let me know if everything is in order or if there's anything else you need.
                <br/>
                If there's anything more I can do to assist, feel free to reach out. I'm here to help ensure the project moves forward smoothly.
                <br/>
                Best Regards,<br/>
                Michael
              </Typography>
              <Button
                variant="contained"
                sx={{
                  position: 'absolute',
                  top: '20px',
                  right: '80px',
                  backgroundColor: '#FF0807',
                  color: '#fff',
                  textTransform: 'none',
                  width: "111px", 
                  height: "35px", 
                  borderRadius: "8px",
                  fontFamily: "Nexa-Heavy",
                  '&:hover': {backgroundColor: "#FF0807"}
                }}
                onClick={this.handleOpenDialog}
                startIcon={<Delete />}
                data-test-id= "delete-button"
              >
                Delete
              </Button>
            </Box>
          )}
          </Box>
        </StyledWrapperSetting>
        <Footer navigation={this.props.navigation} id={"1"} />
        <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog} PaperProps={{
          style: {
            borderBottomRightRadius: '30px',
            width: '27%',
            height: '27%',
            margin: '0 auto',
          },
        }}>
            <IconButton 
              onClick={this.handleCloseDialog} 
              style={{ 
                color: '#fff', 
                position: 'absolute', 
                right: 8, 
                top: 8, 
              }}
              data-test-id= "first-dialog-close-icon"
            >
              <CloseOutlined />
            </IconButton>
          <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C" }}>
            <Typography variant="h6" align="center" style={{ color: "#fff", fontFamily: 'Nexa-Heavy', marginTop: "2%" }} gutterBottom>
              Are you sure you want to delete this message?
            </Typography>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'right', backgroundColor: "#44403C", height: '18%', padding: "5%" }}>
            <Button onClick={this.handleCloseDialog} variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', marginRight: "2%", width: "120px", height: "45px" }} data-test-id="nevermind">
              Nevermind
            </Button>
            <Button onClick={this.handleCloseDialog} variant="contained" style={{ backgroundColor: "#FF0807", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', marginRight: "2%", width: "120px", height: "45px" }} data-test-id="yes-delete">
              Yes, Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  paddingLeft: "calc(2% + 115px)",
  paddingRight: "5%",
  height: "auto",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  });

// Customizable Area End
