// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    TextField,
    Button,
    InputAdornment,
    IconButton,
    Dialog, DialogContent
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmailForgotPasswordControllerScreenSecond, {
    Props,
} from "./EmailForgotPasswordControllerScreenSecond";
import { ForgotPasswordImg2, logoSvg, star, visibility_cross, visibility_icon } from "./assets";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
import { ConditionalRender } from "../../../blocks/utilities/src/ConditionalRender";

export default class EmailForgotPasswordSecondScreen extends EmailForgotPasswordControllerScreenSecond {
    constructor(props: Props) {
        super(props);
    }
    validationSchema = Yup.object().shape({
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "The Passwords Do Not Match.")
            .required("Please confirm your password")
    });
    render() {
        return (
            <>
                <StyleWrapperSocialLink>
                    <Grid className="container">
                        <Grid className="leftContainer">
                            <Formik
                                initialValues={{
                                    password: "",
                                    confirm_password: "",
                                }}
                                data-test-id="formikWrapper"
                                validationSchema={this.validationSchema}
                                onSubmit={(values,{setErrors}) => {
                                    this.resetPassword(values,setErrors)
                                }}
                            >
                                {({ handleChange, handleSubmit, values, errors, touched }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Box className="logoImg">
                                            <img src={logoSvg} alt="logo" />
                                        </Box>
                                        <Box className="title">Forgot Password?</Box>
                                        <Box className="secTitle">Your new password must be different from the previous one.</Box>

                                        <Grid className="fields">
                                            <Box><label className="label">New Password<span>*</span></label></Box>
                                            <TextField id="outlined-basic"
                                                type={ResolveCondition(this.state.enablePasswordField, 'password', 'text')}
                                                value={values.password}
                                                name="password"
                                                onChange={handleChange}
                                                data-test-id="txtInputPassword"
                                                sx={{
                                                    width: "100%",
                                                    marginTop: "4%",
                                                    "& .MuiOutlinedInput-root": {
                                                        "&:hover fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "& fieldset": {
                                                          borderColor: "#CBD5E1",
                                                        },
                                                      "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                      },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                      color: "#CBD5E1",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        backgroundColor: "black", 
                                                      color: "#CBD5E1",
                                                      "&:-webkit-autofill": {
                                                        backgroundColor: "black !important", 
                                                        WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                        WebkitTextFillColor: "#CBD5E1 !important", 
                                                      },
                                                    },
                                                  }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowPassword}
                                                                edge="end"
                                                                sx={{ color: "#CBD5E1" }}
                                                                data-test-id="clickshowPassword"
                                                            >
                                                                <img
                                                                    src={ResolveCondition(this.state.enablePasswordField, visibility_icon, visibility_cross)}
                                                                    alt="toggle visibility"
                                                                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        {ConditionalRender(errors, touched, 'password', <div className="errors">{errors.password}</div>)}
                                        <Box className="password-validation">
                                            <ul style={{ listStylePosition: 'inside', padding: 0, color: "white", fontFamily: "Nexa-Heavy", textTransform: "capitalize" }}>
                                                <li style={{
                                                    listStyleType: 'none',
                                                    color: "white"
                                                }}>Your Password Must Contain: </li>
                                                <li
                                                    style={{
                                                        listStyleType: /[A-Z]/.test(values.password) ? 'disc' : '"X"',
                                                        color: /[A-Z]/.test(values.password) ? "green" : "red",
                                                    }}
                                                >
                                                    {" "} At least one capital letter
                                                </li>
                                                <li
                                                    style={{
                                                        listStyleType: /[a-z]/.test(values.password) ? 'disc' : '"X"',
                                                        color: /[a-z]/.test(values.password) ? "green" : "red",
                                                    }}
                                                >
                                                    {" "} At least one lowercase letter
                                                </li>
                                                <li
                                                    style={{
                                                        listStyleType: /\d/.test(values.password) ? 'disc' : '"X"',
                                                        color: /\d/.test(values.password) ? "green" : "red",
                                                    }}
                                                >
                                                    {" "} At least one number
                                                </li>
                                                <li
                                                    style={{
                                                        listStyleType: values.password.length >= 8 ? 'disc' : '"X"',
                                                        color: values.password.length >= 8 ? "green" : "red",
                                                    }}
                                                >
                                                    {" "}  Minimum character length is 8 Characters
                                                </li>
                                            </ul>
                                        </Box>


                                        <Grid className="fields">
                                            <Box><label className="label">Confirm Password<span>*</span></label></Box>
                                            <TextField id="outlined-basic"
                                                value={values.confirm_password}
                                                name="confirm_password"
                                                type={ResolveCondition(this.state.enableConfirmPasswordField, "password", "text")}
                                                onChange={handleChange}
                                                data-test-id="txtInputConfirmPassword"
                                                sx={{
                                                    width: "100%",
                                                    marginTop: "4%",
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                          borderColor: "#CBD5E1",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                      "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                      },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                      color: "#CBD5E1",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                      color: "#CBD5E1",
                                                      backgroundColor: "black", 
                                                      "&:-webkit-autofill": {
                                                          WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                        backgroundColor: "black !important", 
                                                        WebkitTextFillColor: "#CBD5E1 !important", 
                                                      },
                                                    },
                                                  }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" id="Registration">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowConfirmPassword}
                                                                edge="end"
                                                                sx={{ color: "#CBD5E1" }}
                                                                data-test-id="confirmClickPassword"
                                                            >
                                                                <img
                                                                    src={ResolveCondition(this.state.enableConfirmPasswordField, visibility_icon, visibility_cross)}
                                                                    alt="toggle visibility"
                                                                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />

                                        </Grid>

                                        {ConditionalRender(errors, touched, 'confirm_password', <div className="errors">{errors.confirm_password}</div>)}

                                        <Button type="submit" className="btnContinue" data-test-id="submitBtn">
                                            <b>Reset Password</b>
                                        </Button>
                                        <Box className="login"><b>Remember Your Password?    <a href="EmailAccountLoginBlock" style={{ textDecoration: "none", color: "#1D4ED8", cursor: "pointer" }}>Sign In</a></b></Box>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                        <Grid className="rightContainer">
                            <img src={ForgotPasswordImg2} alt="image_socialLinks" className="imageSignUp" />
                        </Grid>
                    </Grid>
                </StyleWrapperSocialLink>
                <Dialog
                    open={this.state.isModalOpen}
                    PaperProps={{
                        sx: {
                            borderRadius: "8px 8px 32px 8px",
                            backgroundColor: "transparent",
                            height: "350px",
                            width: "600px",
                        },
                    }}
                >
                    <IconButton
                    data-test-id="onCloseModal"
                        onClick={this.handleClose}
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent
                        style={{ backgroundColor: "#44403C", color: "white", padding: 0, }}

                    >
                        <Box>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8%" }}>
                                <img src={star} alt="star" />
                            </Box>

                            <h2 style={{ textAlign: "center", fontFamily: "Nexa-Heavy", height: "20px" }}>Success!</h2>
                            <p style={{ textAlign: "center", fontFamily: "Nexa-Regular", fontWeight: 1000, textTransform: "capitalize", fontSize: "20px" }}>Your password has been <br />successfully reset. You can now <br /> <u>login</u> with your new password.
                            </p>
                        </Box>
                        <Box style={{
                            justifyContent: "center",
                            width: "600px", height: "80px", borderTop: "1px solid white", display: "flex",
                            alignItems: "center"
                        }} id="loginModal">
                            <a href="/">
                                <Button 
                                data-test-id="openModal"
                                style={{
                                    height: "56px",
                                    width: "215px",
                                    borderRadius: "8px",
                                    backgroundColor: "#FF0807",
                                    color: "white",
                                    fontWeight: 900,
                                    fontSize: "14px",
                                    margin: "auto",
                                    marginTop: "20px",
                                    textTransform : "inherit"
                                }}>Go to login</Button>
                            </a>
                        </Box>

                    </DialogContent>

                </Dialog>
            </>
        );
    }

}
const StyleWrapperSocialLink = styled('div')({
    margin: "0 auto",
    height: "auto",
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .secTitle": {
        color: "white",
        fontFamily: "Nexa-Regular",
        fontSize: "12px",
        fontWeight: 400,
        textAlign: "center",
        padding: "20px"
    },
    "& .errors": {
        color: "red",
        fontFamily: "Nexa-Heavy",
        fontWeight: 400,
        fontSize: "12px",
        marginTop: "6px"
    },
    "& .login": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 400,
        fontSize: "13px",
        textAlign: "center",
        marginTop: "4%",
    },
    "& .imageSignUp": {
        width: "100%",
        height: "auto",
        top: "20%"
    },
    "& .leftContainer": {
        padding: "3% 10%",
        width: "45%",
    },
    "& .btnSkip": {
        width: "100%",
        height: "6%",
        color: "white",
        background: "#312F2D",
        fontFamily: "Nexa-Heavy",
        borderRadius: "8px",
        fontWeight: 900,
        fontSize: "18px",
        marginTop: "10%",
        textTransform: "capitalize",
        "&:hover": {
            background: "#312F2D",
            color: "white"
        },
    },
    "& .btnContinue": {
        width: "100%",
        height: "6%",
        color: "white",
        background: "#FF0807",
        fontFamily: "Nexa-Heavy",
        borderRadius: "8px",
        fontWeight: 900,
        fontSize: "18px",
        marginTop: "5%",
        textTransform: "capitalize",
        "&:hover": {
            background: "#FF0807",
            color: "white"
        },
    },
    "& .logoImg": {
        justifyContent: "center",
        display: "flex",
        marginTop: "10%"
    },
    "& .label": {
        fontSize: "14px",
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900
    },
    "& .container": {
        width: "100%",
        height: "auto",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItens: "center"
    },
    "& .title": {
        fontWeight: 900,
        fontSize: "20px",
        color: "white",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        marginBottom: "4%",
        marginTop: "7%",
    },
    "& .fields": {
        marginTop: "4%"
    },
    "& .rightContainer": {
        display: "flex",
        width: "1201px",
        height: "826px",
        justifyContent: "flex-start",
        paddingTop: "5%",
        alignItems: "flex-start",
    },
})
// Customizable Area End
