import React from "react";
import {
  Box,
  styled
} from "@mui/material";
import AppLoader from "../../../components/src/Loader"
import AudioListWeb from "./AudioList.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web"
import Footer from "../../../blocks/navigationmenu/src/Footer.web"; 
import AudioLibraryController, {
  Props
} from "./AudioLibraryController";
import AudioCard from "./components/AudioCard.web";


export default class SavedEpisode extends AudioLibraryController {
  constructor(props: Props) {
    super(props);
    
  }

  renderMedia = () => { return <AudioListWeb totalCount={this.state.totalCount} songs={this.state.audioList} pageNo={this.state.currentPageNo} handlePagination={this.handlePagination}  /> }

  render() {
    const { savedEpisode } = this.state;
    return (
        <div>
          <NavigationMenu navigation={this.props.navigation} id='1' />
          <Box sx={{marginLeft:"16rem"}}> 
  
          <CustomTypography data-test-id ={"saved_episode"} sx={{margin:"16px 0px"}}>
            Saved Episodes
          </CustomTypography>
          <Box display={"flex"} flexWrap={"wrap"} gap={5}>
            {
              savedEpisode.map((data, index) => (
                <AudioCard 
                key={index} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                selected={data.selected}
                added={data.added}
                />
              ))
            }
          </Box>

          </Box>


          <Footer navigation={this.props.navigation} id={"1"} />
        
        {this.renderMedia()}       
        <AppLoader loading={this.state.loading} />
        </div>
    );
  }
}


const CustomTypography = styled(Box)(() => ({
  fontWeight: 900,
  fontSize: "30px",
  lineHeight: "40px",
  letter: "-0.5%",
  color: "#fff",
}));

