import React from "react";
// Customizable Area Start
import { styled, Box, Typography, Button, IconButton, Card, CardContent, CardMedia, Link, Grid } from "@mui/material";
import { Star, StarBorder, StarHalf, Phone, InfoOutlined, AttachMoney, Laptop, ShoppingCartOutlined, LocalOfferOutlined, MenuBook, ThumbUpOffAlt, ChatBubbleOutline, Share, FavoriteBorder, PlaylistAdd, PlayCircleFilled } from '@mui/icons-material';
import { podcastImage, tshirtIcon, recommended_episode_1, recommended_episode_2, recommended_episode_3, ad_1, ad_2 } from "./assets";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
      <NavigationMenu navigation={this.props.navigation} id='1' />
      <StyledWrapperSetting>
        <Box className= "header-section">
          <Box display="flex" flexDirection="row" justifyContent="space-between" gap="20px">
            <Box display="flex" flexDirection="row" gap="20px">
              <img src={podcastImage} alt="Podcast" className= "podacst-image"/>
              <Box className= "podcast-header-content">
                <Typography variant="h5" component="h2" style={{fontWeight: "900", lineHeight: "40px", fontSize: "30px", fontFamily: "Nexa-Heavy"}}>The True Podcast</Typography>
                <Typography className="podcast-name">{this.state.podcastName}</Typography>
                <Box display="flex" alignItems="center">
                  {[...Array(this.state.fullStars)].map((_, index) => (
                    <IconButton key={`full-${index}`} style={{ padding: 0 }}>
                      <Star style={{ color: '#fff' }} />
                    </IconButton>
                  ))}
                  {this.state.hasHalfStar && (
                    <IconButton key="half-star" style={{ padding: 0 }}>
                      <StarHalf style={{ color: '#fff' }} />
                    </IconButton>
                  )}
                  {[...Array(this.state.emptyStars)].map((_, index) => (
                    <IconButton key={`empty-${index}`} style={{ padding: 0 }}>
                      <StarBorder style={{ color: '#fff' }} />
                    </IconButton>
                  ))}
                </Box>
                <Box display="flex" gap="8px" marginBottom="10px">
                    <Button variant="outlined" className= "left-section-buttons">Rate or Review</Button>
                    <Button variant="outlined" className= "left-section-buttons">Share Channel</Button>
                  </Box>
                <Typography className= "total-reviews">{this.state.totalReviews}K Reviews</Typography>
                <Box display="flex" gap="10px" marginTop="10px">
                  <Button variant="contained" className= "left-section-bottom-buttons" data-test-id= "follow-button" style={{backgroundColor: (this.state.followButtonText === "Follow" ? "#FF5722" : "black")}} onClick={() => this.handleFollowButton()}>{this.state.followButtonText}</Button>
                  <Button variant="contained" className= "left-section-bottom-buttons"><AttachMoney style={{width: "24px", height: "24px"}}/>Subscribe</Button>
                  <IconButton className= "podcast-header-info-icon"><InfoOutlined /></IconButton>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-start" style={{ marginTop: '40px' }}>
              <Box display="flex" flexDirection="column" gap="20px">
                <Box display="flex" flexDirection="row" gap="20px">
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Button variant="outlined" style={{ backgroundColor: '#FF0807', borderColor: '#FF0807', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><Laptop style={{width: "24px", height: "20px"}}/>Webinar</Button>
                    <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px",  textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><img src={tshirtIcon} />Buy Swag</Button>
                    <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px",  textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><AttachMoney style={{width: "24px", height: "24px"}}/>Support Host</Button>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Button variant="outlined" style={{ backgroundColor: '#FF0807', borderColor: '#FF0807', color: '#fff', width: '164px', height: "44px", borderRadius: "8px",  textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><ShoppingCartOutlined style={{width: "24px", height: "20px"}}/>Buy Now</Button>
                    <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px",  textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><MenuBook style={{width: "24px", height: "20px"}}/>Learn More</Button>
                    <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px",  textTransform: 'capitalize', fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><LocalOfferOutlined style={{width: "24px", height: "20px"}}/>Get Offer</Button>
                  </Box>
                </Box>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Button variant="outlined" style={{ color: '#fff', width: '345px', height: "44px", borderRadius: "8px", border: "1px solid white", fontWeight: "bold", textTransform: "capitalize", fontFamily: "Nexa-Heavy" }} startIcon={<Phone />}>
                      Contact Host
                    </Button>
                  </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className= "left-body-section-tabs">
          <Box sx={{ display: 'flex', gap: '22px' }}>
          <Button variant="outlined" className= "section-tabs" data-test-id= "tab-button" onClick={() => this.handleTabChanges('Episodes')} sx={{ borderRadius: (this.state.selectedTab === 'Episodes' ? '9px 9px 0px 0px' : '9px'), height:  (this.state.selectedTab === 'Episodes' ? '53px' : '40px') }}>Episodes</Button>
          <Button variant="outlined" className= "section-tabs" data-test-id= "tab-button" onClick={() => this.handleTabChanges('Community')} sx={{ borderRadius: (this.state.selectedTab === 'Community' ? '9px 9px 0px 0px' : '9px'), height:  (this.state.selectedTab === 'Community' ? '53px' : '40px')  }}>Community</Button>
          <Button variant="outlined" className= "section-tabs"  data-test-id= "tab-button" onClick={() => this.handleTabChanges('ChannelInfo')} sx={{ borderRadius: (this.state.selectedTab === 'ChannelInfo' ? '9px 9px 0px 0px' : '9px'), height:  (this.state.selectedTab === 'ChannelInfo' ? '53px' : '40px')  }}>Channel Info</Button>
          </Box>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '65% 35%', gap: '24px' }}>
        <div className="scrollableWrapper">
          <Box className= "left-body-section" sx={{overflowY: 'scroll', maxHeight: '64rem'}}>
            <Card className= "podcast-card">
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={podcastImage}
                  alt="Podcast episode"
                  className="podcast-media"
                />
              <Box className= "podcast-inner-box">
                <CardContent className= "podcast-inner-box-content">
                  <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                    The Cost Of Success
                  </Typography>
                  <Typography variant="subtitle1" color="#fff" component="div" id= "ep-10" className="podcast-inner">
                    The True
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
                    Episode 10
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                    Sed ut perspiciatis unde omnis iste natus error sit ...
                    <Link style={{ color: '#fff' }} id= "ep-10-link">See More</Link>
                  </Typography>
                </CardContent>
                <Box className= "podcast-action-buttons">
                  <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-10-like">
                    <ThumbUpOffAlt />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">1.4K</Typography>
                  <IconButton aria-label="comment" sx={{ color: 'white' }}>
                    <ChatBubbleOutline />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">985</Typography>
                  <IconButton aria-label="share" sx={{ color: 'white' }}>
                    <Share />
                  </IconButton>
                  <Typography sx={{ color: 'white' }} className="podcast-inner">1.2K</Typography>
                </Box>
              </Box>
              <Box className= "podcast-saving-options" id= "ep-10-buttons">
                <Button variant="outlined" className= "podcast-save-button" startIcon={<FavoriteBorder />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy"}}>Save For Later</Typography>
                </Button>
                <Button variant="outlined" className= "podcast-save-button" startIcon={<PlaylistAdd />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy"}}>Add to Playlist</Typography>
                </Button>
              </Box>
              <Box className= "play-icon" id= "ep-10-play-button">
                <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
              </Box>
            </Card>
            <Card className= "podcast-card">
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={podcastImage}
                  alt="Podcast episode"
                  className="podcast-media"
                />
              <Box className= "podcast-inner-box">
                <CardContent className= "podcast-inner-box-content">
                  <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                    The Cost Of Process
                  </Typography>
                  <Typography variant="subtitle1" color="#fff" component="div" id="ep-9" className="podcast-inner">
                    The True
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
                    Episode 9
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                    Sed ut perspiciatis unde omnis iste sit voluptatem accusantium...
                    <Link style={{ color: '#fff' }} id="ep-9-link">See More</Link>
                  </Typography>
                </CardContent>
                <Box className= "podcast-action-buttons">
                  <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-9-like">
                    <ThumbUpOffAlt />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">1.3K</Typography>
                  <IconButton aria-label="comment" sx={{ color: 'white' }}>
                    <ChatBubbleOutline />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">984</Typography>
                  <IconButton aria-label="share" sx={{ color: 'white' }}>
                    <Share />
                  </IconButton>
                  <Typography sx={{ color: 'white' }} className="podcast-inner">1.3K</Typography>
                </Box>
              </Box>
              <Box className= "podcast-saving-options" id="ep-9-button">
                <Button variant="outlined" className= "podcast-save-button" startIcon={<FavoriteBorder />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy"}}>Save For Later</Typography>
                </Button>
                <Button variant="outlined" className= "podcast-save-button" startIcon={<PlaylistAdd />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy"}}>Add to Playlist</Typography>
                </Button>
              </Box>
              <Box className= "play-icon" id="ep-9-play-button">
                <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
              </Box>
            </Card>
            <Card className= "podcast-card">
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={podcastImage}
                  alt="Podcast episode"
                  className="podcast-media"
                />
              <Box className= "podcast-inner-box">
                <CardContent className= "podcast-inner-box-content">
                  <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                    The Cost Of Failure
                  </Typography>
                  <Typography variant="subtitle1" color="#fff" component="div" id="ep-8" className="podcast-inner">
                    The True
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
                    Episode 8
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                    Sed ut perspiciatis iste natus error sit voluptatem accusantium...
                    <Link style={{ color: '#fff' }} id="ep-8-link">See More</Link>
                  </Typography>
                </CardContent>
                <Box className= "podcast-action-buttons">
                  <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-8-like">
                    <ThumbUpOffAlt />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">1.5K</Typography>
                  <IconButton aria-label="comment" sx={{ color: 'white' }}>
                    <ChatBubbleOutline />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">988</Typography>
                  <IconButton aria-label="share" sx={{ color: 'white' }}>
                    <Share />
                  </IconButton>
                  <Typography sx={{ color: 'white' }} className="podcast-inner">1.9K</Typography>
                </Box>
              </Box>
              <Box className= "podcast-saving-options" id="ep-8-button">
                <Button variant="outlined" className= "podcast-save-button" startIcon={<FavoriteBorder />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy"}}>Save For Later</Typography>
                </Button>
                <Button variant="outlined" className= "podcast-save-button" startIcon={<PlaylistAdd />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy"}}>Add to Playlist</Typography>
                </Button>
              </Box>
              <Box className= "play-icon" id="ep-8-play-button">
                <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
              </Box>
            </Card>
            <Card className= "podcast-card">
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={podcastImage}
                  alt="Podcast episode"
                  className="podcast-media"
                />
              <Box className= "podcast-inner-box">
                <CardContent className= "podcast-inner-box-content">
                  <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                    The Cost Of You
                  </Typography>
                  <Typography variant="subtitle1" color="#fff" component="div" id="ep-7" className="podcast-inner">
                    The True
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
                    Episode 7
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                    Sed ut iste natus error sit voluptatem accusantium...
                    <Link style={{ color: '#fff' }} id="ep-7-link">See More</Link>
                  </Typography>
                </CardContent>
                <Box className= "podcast-action-buttons">
                  <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-7-like">
                    <ThumbUpOffAlt />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">2.3K</Typography>
                  <IconButton aria-label="comment" sx={{ color: 'white' }}>
                    <ChatBubbleOutline />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">884</Typography>
                  <IconButton aria-label="share" sx={{ color: 'white' }}>
                    <Share />
                  </IconButton>
                  <Typography sx={{ color: 'white' }} className="podcast-inner">2.2K</Typography>
                </Box>
              </Box>
              <Box className= "podcast-saving-options" id="ep-7-buttons">
                <Button variant="outlined" className= "podcast-save-button" startIcon={<FavoriteBorder />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy"}}>Save For Later</Typography>
                </Button>
                <Button variant="outlined" className= "podcast-save-button" startIcon={<PlaylistAdd />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy"}}>Add to Playlist</Typography>
                </Button>
              </Box>
              <Box className= "play-icon" id="ep-7-play-button">
                <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
              </Box>
            </Card>
            <Card className= "podcast-card">
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={podcastImage}
                  alt="Podcast episode"
                  className="podcast-media"
                />
              <Box className= "podcast-inner-box">
                <CardContent className= "podcast-inner-box-content">
                  <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                    The Cost Of Me
                  </Typography>
                  <Typography variant="subtitle1" color="#fff" component="div" id="ep-11" className="podcast-inner">
                    The True1
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
                    Episode 11
                  </Typography>
                  <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                    Sed ut iste natus error sit accusantium...
                    <Link style={{ color: '#fff' }} id="ep-11-link">See More</Link>
                  </Typography>
                </CardContent>
                <Box className= "podcast-action-buttons">
                  <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-11-like">
                    <ThumbUpOffAlt />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">1.1K</Typography>
                  <IconButton aria-label="comment" sx={{ color: 'white' }}>
                    <ChatBubbleOutline />
                  </IconButton>
                  <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">770</Typography>
                  <IconButton aria-label="share" sx={{ color: 'white' }}>
                    <Share />
                  </IconButton>
                  <Typography sx={{ color: 'white' }} className="podcast-inner">5.1K</Typography>
                </Box>
              </Box>
              <Box className= "podcast-saving-options" id="ep-11-buttons">
                <Button variant="outlined" className= "podcast-save-button" startIcon={<FavoriteBorder />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: 'Nexa-Heavy'}}>Save For Later</Typography>
                </Button>
                <Button variant="outlined" className= "podcast-save-button" startIcon={<PlaylistAdd />}>
                  <Typography style={{fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy"}}>Add to Playlist</Typography>
                </Button>
              </Box>
              <Box className= "play-icon" id="ep-11-play-button">
                <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
              </Box>
            </Card>
          </Box>
          </div>
          <Box sx={{ minHeight: '100vh'}}>
            <Grid container direction="column" spacing={2} className= "right-body-section">
              <Typography className= "sponsored-title">Sponsored Recommendations</Typography>
              <Grid item>
                <Card className= "recommended-cards">
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>
                      <Typography variant="h6" sx={{ color: '#fff', fontFamily: "Nexa-Heavy" }}>
                        The True
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#fff', fontFamily: "Nexa-Regular" }}>
                        Cost of Success Ep. 9
                      </Typography>
                    </CardContent>
                  </Box>
                  <CardMedia
                    component="img"
                    image={recommended_episode_1} 
                    alt="The True Episode"
                    sx={{
                      width: "20%",
                      height: "100%",
                      marginLeft: 'auto',
                      objectFit: 'cover'
                    }}
                  />
                </Card>
              </Grid>
              <Grid item>
                <Card className= "recommended-cards">
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>
                      <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }}>
                        All Consuming
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#fff', fontFamily: 'Nexa-Regular' }}>
                        Fantomy Ep. 12
                      </Typography>
                    </CardContent>
                  </Box>
                  <CardMedia
                    component="img"
                    image={recommended_episode_2} 
                    alt="The True Episode"
                    sx={{
                      width: "20%",
                      height: "100%",
                      marginLeft: 'auto',
                      objectFit: 'cover'
                    }}
                  />
                </Card>
              </Grid>
              <Grid item>
                <Card className= "recommended-cards">
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>
                      <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }}>
                        This American Life
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#fff', fontFamily: 'Nexa-Regular' }}>
                        Love your era Ep. 8
                      </Typography>
                    </CardContent>
                  </Box>
                  <CardMedia
                    component="img"
                    image={recommended_episode_3} 
                    alt="The True Episode"
                    sx={{
                      width: "20%",
                      height: "100%",
                      marginLeft: 'auto',
                      objectFit: 'cover'
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
            <Box display= "flex" flexDirection= "column" gap= "25px">
              <Typography variant="h6" sx={{ color: '#fff', marginTop: 4, fontFamily: 'Nexa-Heavy' }}>
                Advertising
              </Typography>
              <Box
                component="img"
                src={ad_1}
                alt="Burger Ad"
                sx={{ height: '20rem', borderRadius: "13px" }}
              />
              <Box
                component="img"
                src={ad_2}
                alt="Heinz Ad"
                sx={{ height: '20rem', borderRadius: "13px" }}
              />
            </Box>
          </Box>
        </Box>
      </StyledWrapperSetting>
      </>
      
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  height: "auto",
  // width: "100%",
  paddingRight: "5%",
  paddingLeft: "calc(6% + 135px)",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
 "& .header-section": {
    backgroundColor: "#44403C",
    width: "102%",             
    minHeight: "20rem",                 
    borderRadius: "0.5rem",     
    padding: "1rem",  
    // marginLeft: "90px",
    marginTop: "18px"         
  },
  "& .podcast-name": {
    fontWeight: "900",
    fontSize: "40px",
    lineHeight: "56px",
    textTransform: "uppercase",
    fontFamily: "Nexa-Heavy"
  },
  "& .podacst-image": {
     width: "269px", 
     height: "259px", 
     borderRadius: "5px",
     marginTop: "13px",
     marginLeft: "8px"
  },
  "& .podcast-header-content": {
    color: "white", 
    fontWeight: "bold",
    marginTop: "13px"
  },
  "& .left-section-buttons": {
    color: "white", 
    border: "1px solid white", 
    borderRadius: "8px", 
    marginTop: "5px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      color: "white", 
      border: "1px solid white", 
    }
  },
  "& .podcast-header-info-icon": {
    width: "44px", 
    height: '44px', 
    borderRadius: "8px", 
    color: "white", 
    backgroundColor: "#FF0807",
    marginTop: "5px",
    "&:hover": {
      backgroundColor: "#FF0807",
    }
  },
  "& .total-reviews": {
    fontWeight: "900",
    fontSize: "18px",
    lineHeight: "22px",
    marginTop: "15px",
    fontFamily: "Nexa-Heavy"
  },
  "& .left-section-bottom-buttons": {
    backgroundColor: "#FF5722", 
    color: "white", 
    borderRadius: "8px",
    width: "164px",
    height: '44px',
    fontWeight: "bold",
    textTransform: 'capitalize',
    borderColor: "#FF5722",
    marginTop: "5px",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: "#FF5722", 
      borderColor: "#FF5722",
    }
  },
  "& .left-body-section-tabs": {
    // width: "536px",
    height: "52px",
    // marginLeft: "90px",
    marginTop: "22px"
  },
  "& .section-tabs": {
    color: 'white', 
    backgroundColor: '#44403C',
    textTransform: "capitalize",
    borderColor: "#44403C",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
    backgroundColor: '#44403C',
    borderColor: "#44403C",
    },
    marginBottom: "0px"
  },
 "& .scrollableWrapper": {
  height: "65rem",
  position: "relative",
  paddingRight: "20px", 
  overflow: "hidden", 
},
"& .left-body-section": {
  height: "100%",
  width: "100%",
  overflowY: "scroll", 
  paddingRight: "10px",
  scrollbarWidth: "thin", 
  scrollbarColor: "#FF4500 #4D4D4D", 

  "&::-webkit-scrollbar": {
    width: "15px", 
  },
  "&::-webkit-scrollbar-track": {
    background: "#4D4D4D",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#FF4500",
    borderRadius: "10px", 
  },
},
  "& .podcast-card": {
    display: 'flex', 
    backgroundColor: '#44403C',
    gap: "24px",
    // width: "1005px",
    height: "242px",
    "&:first-of-type": {
      marginTop: "0px",  
    },
    "&:not(:first-of-type)": {
      marginTop: "20px", 
    }
  },
  "& .podcast-inner-box": {
    display: 'flex', 
    flexDirection: 'column', 
    flex: 1 
  },
  "& .podcast-inner-box-content": {
    flex: '1 0 auto', 
    paddingBottom: 0
  }, 
  "& .podcast-action-buttons": {
    display: 'flex', 
    alignItems: 'center', 
    paddingLeft: 2, 
    paddingBottom: 10
  },
  "& .podcast-saving-options": {
    display: 'flex', 
    flexDirection: 'column', 
    gap: "9px"
  },
  "& .podcast-save-button": {
    color: 'white', 
    borderColor: '#FF0807',
    marginRight: "0px",
    width: "118px",
    height: "26px",
    backgroundColor: "#FF0807",
    borderRadius: "8px",
    textTransform: "capitalize",
    gap: "8px",
    "&:hover": {
      borderColor: '#FF0807', 
      backgroundColor: "#FF0807",
    },
    "&:first-of-type": {
      marginTop: "25px",  
    },
    "&:not(:first-of-type)": {
      marginTop: "0px", 
    }
  },
  "& .podcast-media": {
    width: "123px",
    height: "118px",
    marginTop: "34px",
    marginLeft: "44px",
    borderRadius: "3px"
  }, 
  "& .play-icon": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '10%',
    marginRight: "2%",
    marginTop: "19%"
  },
  "& .sponsored-title": {
    color: "#fff",
    marginLeft: "15px",
    fontFamily: 'Nexa-Heavy'
  },
  "& .right-body-section": {
    marginTop: "-35px", 
    // width: "47.8%", 
    // height: "33%"
  },
  "& .recommended-cards": {
    display: 'flex', 
    backgroundColor: '#44403C', 
    color: '#fff', 
    // width: '478px', 
    height: '80px', 
    borderRadius: '6px',
    borderLeft: '5px solid red'
  },
  "& .podcast-inner": {
    fontFamily: 'Nexa-Heavy'
  }
});

// Customizable Area End
