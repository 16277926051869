// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  data: any,
  flag: boolean,
  editablePrice: any,  
  editFlags: any,
  editableFeatures : any,
  checkedFeatures : any
}

export interface SS {
  id: any;
}

export default class ChooseFeatureSubscriptionTierController extends BlockComponent<
  Props,
  S,
  SS
> {

  subscriptionTierApiCallId = ""
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      data: [],
      flag: false,
      editablePrice: "",
      editFlags: [],
      editableFeatures: [],
      checkedFeatures : {}
    };
  }

  async receive(from: string, message: Message) {
    if (this.subscriptionTierApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResSubsTiers(message);
    }
  }

  async componentDidMount() {
    const localData = await getStorageData("subscriptionTiers",true);
    const localCheckedFeatures = await getStorageData("checkedFeatures");

    if (localData) {
      const editFlags = localData.map(() => false);
      this.setState({ data: localData, editFlags});
    } else {
      await this.subcriptionTiers();
    }

    if (localCheckedFeatures) {
      this.setState({ checkedFeatures: JSON.parse(localCheckedFeatures) });
    }
  }
  toggleEditMode = (index: number) => {
    const editFlags = [...this.state.editFlags];
    editFlags[index] = !editFlags[index];
  
    const editablePrice = [...this.state.editablePrice];
    if (!editFlags[index]) {
      editablePrice[index] = ""; 
    } else {
      const price = this.state.data[index].attributes.price || "";
      editablePrice[index] = `$${price}`.replace(/^\$+/, ""); 
    }
  
    this.setState({ editFlags, editablePrice });
  };
  
  handlePriceChange = (index: number, value: string) => {
    const numericValue = value.replace(/^\$+/, ""); 
    const editablePrice = [...this.state.editablePrice];
  
    editablePrice[index] = numericValue;
    this.setState({ editablePrice });
  };
  
  subcriptionTiers = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.subscriptionTierApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscription_tiers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResSubsTiers = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseJson.data) {
      console.log(responseJson.data, "data");
      const editFlags = responseJson.data.map(() => false);
      setStorageData("subscriptionTiers", JSON.stringify(responseJson.data));
      this.setState({ data: responseJson.data, editFlags });
    }
  };

}
// Customizable Area End
