import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVisible: boolean;
  showReplyBox: boolean;
  message: string;
  isMessageSent: boolean;
  openDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReplyToController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      isVisible: false,
      showReplyBox: false,
      message: "",
      isMessageSent: false,
      openDialog: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleReply = () => {
   this.setState({showReplyBox: true});
  }
  handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({message: event.target.value});
  };
  handleSend = () => {
    this.setState({isMessageSent: true, showReplyBox: false})
  }
  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  handleOpenDialog = () => {
    this.setState({openDialog: true})
  }
  handleCloseDialog = () => {
    this.setState({openDialog: false})
  }
  // Customizable Area End
}
