import React from "react";

import {
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { back, podcastImage2} from "./assets";
import Slider from 'react-slick';
import AppLoader from "../../../components/src/Loader"
import AudioListWeb from "./AudioList.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web"
import Footer from "../../../blocks/navigationmenu/src/Footer.web"; 
import ImageCard from "./components/ImageCard.web";
import AudioCard from "./components/AudioCard.web";
interface PlaylistCardProps extends BoxProps {
  bgColor?: string; // Optional bgColor prop
}

const CustomTypography = styled(Box)(({ theme }) => ({
  fontWeight: 900,
  fontSize: "30px",
  lineHeight: "40px",
  letter: "-0.5%",
  color: "#fff",
  marginBottom: "24px"
}));

const PlaylistCard = styled(Box)<PlaylistCardProps>(({ theme,bgColor  }) => ({
  width: '141px',
  height: '97px',
  padding: '16px 12px 16px 9px',
  borderRadius: '6px',
  backgroundColor: bgColor ||"red",
  color: bgColor ==="white"? "black" : "white",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center"
}));

const PlaylistContainer = styled(Box)<PlaylistCardProps>(({theme, bgColor }) => ({
  width: "520px",
  height: "130px",
  borderRadius:"6px",
  background:"#44403C",
  // padding:"16px 12px 16px 20px",
  borderLeft:`4px solid ${bgColor}`,
  color:"white",
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center"
}))

// Customizable Area End

import AudioLibraryController, {
  Props
} from "./AudioLibraryController";

export default class AudioLibrary extends AudioLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMedia = () => { return <AudioListWeb totalCount={this.state.totalCount} songs={this.state.audioList} pageNo={this.state.currentPageNo} handlePagination={this.handlePagination}  /> }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div>
          <NavigationMenu navigation={this.props.navigation} id='1' />
          <Box sx={{marginLeft:"14rem", marginBottom: "40px"}}> 
          <CustomTypography>
            Continue Listen
          </CustomTypography>
                      
          {this.state.continueListen.map((playlist, index) => (
            <Box key={index} display={"flex"} gap={2} mb={1}>
              <PlaylistCard bgColor={playlist.bgcolor}>
                <img
                  data-test-id="playlist"
                  src={playlist.playIcon} // Using the playIcon from the playlist
                  alt="playlist icon"
                  style={{ cursor: "pointer" }}
                />
                <Typography sx={{ fontWeight: 900, fontSize: "14px", lineHeight: "24px" }}>
                  {playlist.title}
                </Typography>
              </PlaylistCard>

              {playlist.playLists.map((song, songIndex) => (
                <PlaylistContainer bgColor={playlist.bgcolor} key={songIndex}>
                  <Box padding={"16px 12px 16px 20px"}>
                    <Typography sx={{ fontWeight: 900, fontSize: "14px", lineHeight: "24px" }}>
                      {song.songTitle}
                    </Typography>
                    <Typography sx={{ fontWeight: 100, fontSize: "14px", lineHeight: "22px", color: "white" }}>
                      {song.songSubTitle}
                    </Typography>
                  </Box>
                  <Box>
                    <img
                      data-test-id="playlist"
                      src={podcastImage2}
                      alt="playlist icon"
                      style={{ cursor: "pointer", width: "112px", height: "130px", objectFit:"cover" }}
                    />
                  </Box>
                </PlaylistContainer>
              ))}
            </Box>
          ))}

          <CustomTypography sx={{margin:"24px 0px",}} onClick={this.handlePodCastFollowing}>
            Podcasts I'm Following
          </CustomTypography>
          <Box sx={{position: 'relative'}} height={"350px"}>
          <Slider {...this.getSlickSettings()}>
            {
              this.state.followingPodCasts.map((data, index) => (
                <ImageCard
                key={index} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                following={data.following}
              />
              ))
            }
          </Slider>
          </Box>
          <CustomTypography data-test-id ={"saved_episode"} sx={{margin:"24px 0px"}} onClick={this.handleSavedEpisode}>
            Saved Episodes
          </CustomTypography>
          <Box sx={{position: 'relative'}} height={"350px"}>
          <Slider {...this.getSlickSettings()}>
            {
              this.state.savedEpisode.map((data, index) => (
                <AudioCard 
                key={index} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                selected={data.selected}
                added={data.added}
                />
              ))
            }
          </Slider>
          </Box>

          <CustomTypography sx={{margin:"24px 0px"}} onClick={this.handleChannelLibrary}>
            Channel Library
          </CustomTypography>
          <Box sx={{position: 'relative'}} height={"360px"}>
          <Slider {...this.getSlickSettings()}>
          {
              this.state.channelLibrary.map((data, index) => (
                <AudioCard 
                key={index} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                selected={data.selected}
                added={data.added}
                />
              ))
            }
          </Slider>
          </Box>
          </Box>
          <Footer navigation={this.props.navigation} id={"1"} />

          <div style={{display:"flex", justifyContent:"center", padding:"28px", background:"linear-gradient(#ABA6F3,#8E84DA)", color:"#fff"}}>
         <div style={{width:"50px", height:"50px"}}>
          <img src={back} />
         </div>
          {(this.state.header)? (
            <Typography
            style={{fontWeight:"bold"}}
            >
              {this.state.header}
            </Typography>
          ): null}  
        </div>     
        {this.renderMedia()}       
        <AppLoader loading={this.state.loading} />
        </div>
      // Customizable Area End
    );
  }
}
