// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
} from "@mui/material";
import HostSetupMethodController, {
    Props,
} from "./HostSetupMethodController";
import { leftImage, rightImage } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
const MIN_WINDOW_HEIGHT = 900

export default class HostSetupMethod extends HostSetupMethodController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id='1' />
                <div style={webStyles.container}>
                    <StyledWrapperHostSetup>
                        <Box className="container">
                            <Box className="upper"></Box>
                            <Box className="title">
                                <Box className="title1">Let's get Started!</Box>
                                <Box className="title1">Choose Your Setup Method Below</Box>
                            </Box>
                            <Box className="mainContainer">
                                <Box className="container1">
                                    <Box className="image">
                                        <img src={leftImage} alt="leftImage" className="image" />
                                    </Box>
                                    <Box className="desc">
                                        I don’t have an RSS feed, so I want to start a new podcast
                                    </Box>
                                </Box>
                                <Box className="container1">
                                    <Box className="image">
                                        <img src={rightImage} alt="rightImage" className="image" />
                                    </Box>
                                    <Box className="desc">I already have a podcast with an RSS Feed</Box>
                                </Box>
                            </Box>
                            <Box className="buttons">
                                <a href="/SetupPodcastProfileNoRssFeed"><Button className="btn1">Create New Podcast</Button></a>
                                <a href="/HostSetupRSSFeed"><Button className="btn1">Import Existing RSS Feed</Button></a>
                            </Box>
                        </Box>
                    </StyledWrapperHostSetup>
                    <Footer navigation={this.props.navigation} id={"1"} />
                </div>
            </>

        );
    }

}
const StyledWrapperHostSetup = styled("div")({
    height: "auto",
    paddingRight: "1.5%",
    paddingLeft: "8.5%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .buttons": {
        width: "753px",
        height: "56px",
        margin: "auto",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "1%",
    },
    "& .btn1": {
        height: "56px",
        width: "360px",
        background: "#FF0807",
        color: "white",
        borderRadius: "8px",
        textTransform: "capitalize",
        fontSize: "14px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        "&:hover": {
            background: "#FF0807",
        },
        cursor: "pointer"
    },
    "& .desc": {
        background: "#312F2D",
        height: "95px",
        width: "360px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        marginTop: "2%"
    },
    "& .image": {
        height: "223px",
        width: "358px",
    },
    "& .container1": {
        height: "100%",
        width: "368px"
    },
    "& .mainContainer": {
        height: "326px",
        width: "760px",
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },

    "& .title": {
        height: "17%",
        width: "50%",
        margin: "auto",
        marginTop: "2%",
        justifyContent: "space-between"
    },
    "& .title1": {
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontWeight: 900,
        fontSize: "30px",
    },
    "& .container": {
        borderRadius: "8px",
        color: "white",
        height: "707px",
        width: "90%",
        margin: "auto",
        background: "#44403C",
        marginBottom: "3%"
    },
    "& .upper": {
        height: "15%",
        background: "#FF0807",
        borderRadius: "8px",
    }
})
const webStyles = {
    container: {
        height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
        overflowY: "scroll" as const,
        scrollbarWidth: "thin",
        scrollbarColor: "red gray",
        "&::-webkit-scrollbar": {
            width: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "red",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "gray",
        },
        "&::-webkit-scrollbar-button": {
            display: "none",
        },
    } as const
}
// Customizable Area End
