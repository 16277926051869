// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button
} from "@mui/material";
import HostMonetizationChooseTemplateController, {
    Props,
} from "./HostMonetizationChooseTemplateController";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import {  chooseTemplate1, chooseTemplate2, chooseTemplate3, goBack } from "./assets";
import {  handleNavigation } from "../../utilities/src/CustomHelpers";

export default class HostMonetizationChooseTemplate extends HostMonetizationChooseTemplateController {
    constructor(props: Props) {
        super(props);
    }
  
    render() {
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id='1' />
                <StyledWrapperSponsoredPost>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box className="mainTitle" id= "hostmonetizationchooseTemplate" style={{ display: "flex", alignItems: "center",marginTop:"10px",marginBottom : "10px",marginLeft: "60px" }}>
                            <img
                                alt="goBack"
                                src={goBack}
                                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                                data-test-id="navigate"
                                onClick={() => { handleNavigation("HostMonetizationSetup", this.props) }}
                            />
                            Back
                        </Box>
                        <Box className="upperBtn" style={{ marginLeft: "auto", marginRight: "10px"}}>
                            Re-Set Up Monetization
                        </Box>
                        <Box className="upperBtn" style={{marginRight : "70px"}} id= "hostmonetizationchooseTemplate">
                            Save For Later
                        </Box>
                    </Box>
                    <Box className="scrollHostMonetization" id= "hostmonetizationchooseTemplate">
                        <Box className="container">
                            <Box className="subContainer" id= "hostmonetizationchooseTemplate">
                                <Box className="upperContent">
                                    <Box className="title">Choose Features for Your Subscription Tiers</Box>
                                    <Box className="desc">Select the features you want to include in your membership tiers. You can choose multiple options.</Box>
                                </Box>
                                <Box className="lowerContent">
                                    <Box className="mainWrapper">
                                            <Box
                                                className="wrapper"
                                                data-test-id="boxSelectedimg"
                                                onClick={() => this.handleItemClick("Access to Premium Episodes")}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    background: "#312F2D",
                                                    alignItems: "center",
                                                    margin: "15px",
                                                    borderRadius: "16px",
                                                    marginTop: "3%",
                                                    width: "auto",
                                                    border: this.state.subscriptionTiers.includes("Access to Premium Episodes") 
                                                        ? '5px solid #FF0807' 
                                                        : 'none',
                                                }}                                            
                                            >                                              
                                                <Box
                                                    style={{
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        height : "223px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <img
                                                        src={chooseTemplate1}
                                                        alt="chooseTemplate1"
                                                        style={{
                                                            display: "block",
                                                            width: "100%",
                                                            height: "auto",
                                                        }}
                                                    />
                                                </Box>
                                                <p className="itemTitle">Access to Premium Episodes</p>
                                            </Box>
                                            <Box
                                                className="wrapper"
                                                data-test-id="boxSelectedimg1"
                                                onClick={() => this.handleItemClick("Access to the Community")}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    margin: "15px",
                                                    background: "#312F2D",
                                                    borderRadius: "16px",
                                                    width: "auto",
                                                    marginTop: "3%",
                                                    border: this.state.subscriptionTiers.includes("Access to the Community") 
                                                        ? '5px solid #FF0807' 
                                                        : 'none',
                                                }}
                                            
                                            >
                                              
                                                <Box
                                                    style={{
                                                        display: "inline-block",
                                                        width: "100%",
                                                        overflow: "hidden",
                                                        height : "223px"
                                                    }}
                                                >
                                                    <img
                                                        src={chooseTemplate2}
                                                        alt="chooseTemplate2"
                                                        style={{
                                                            display: "block",
                                                            width: "100%",
                                                            height: "auto",
                                                        }}
                                                    />
                                                </Box>
                                                <p className="itemTitle">Access to the Community</p>
                                            </Box>
                                            <Box
                                                className="wrapper"
                                                data-test-id="boxSelectedimg2"
                                                onClick={() => this.handleItemClick("Ad-Free Experience")}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    margin: "15px",
                                                    background: "#312F2D",
                                                    borderRadius: "16px",
                                                    width: "auto",
                                                    marginTop: "3%",
                                                    border: this.state.subscriptionTiers.includes("Ad-Free Experience") 
                                                        ? '5px solid #FF0807' 
                                                        : 'none',
                                                }}                                            
                                            >                                              
                                                <Box
                                                    style={{
                                                        display: "inline-block",
                                                        width: "100%",
                                                        overflow: "hidden",
                                                        height : "223px"
                                                    }}
                                                >
                                                    <img
                                                        src={chooseTemplate3}
                                                        alt="chooseTemplate3"
                                                        style={{
                                                            display: "block",
                                                            width: "100%",
                                                            height: "auto",
                                                        }}
                                                    />
                                                </Box>
                                                <p className="itemTitle">Ad-Free Experience</p>
                                            </Box>
                                    </Box>

                                </Box>
                                    <Button 
                                    className={this.state.subscriptionTiers.length > 0 ? "btn btnColor" : "btn"} 
                                    disabled={this.state.subscriptionTiers.length > 0 ? false : true} 
                                    data-test-id="dataNavigations"
                                    onClick={()=>{
                                        handleNavigation("ChooseFeatureSubscriptionTier",this.props)
                                    }}
                                   >Continue</Button>
                            </Box>
                        </Box>
                    </Box>
                </StyledWrapperSponsoredPost>
            </>

        );
    }

}
const StyledWrapperSponsoredPost = styled("div")({
    background: "black",
    paddingRight: "3%",
    paddingLeft: "calc(6% + 100px)",
    "& *": {
        boxSizing: "border-box",
    },
    "& .upperBtn": {
        width: "auto",
        height: "26px",
        borderRadius: "8px",
        fontWeight: 400,
        color: "white",
        fontSize: "16px",
        fontFamily: "Nexa-Heavy",
        background: "#312F2D",
        marginRight: "26px"
    },
    "& .btn": {
        height: "56px", 
        margin: "auto",
        cursor: "pointer",
        marginTop : "3%",
        marginBottom : "4%",
        color: "white",
        background: "#B2B3B3",
        width: "30%",
        borderRadius: "8px",
        justifyContent: "center",
        display: "flex",
        fontWeight: 900,
        alignItems: "center",
        fontFamily: "Nexa-Heavy",
        fontSize: "14px",
        textTransform: "capitalize",
        "&:hover": {
            background: "#B2B3B3",
        },
        "&:disabled": {
            color: "white",
          }
    },
    "& .btnColor":{
        background : "#FF0807",
        "&:hover":{
        background : "#FF0807",
        }
    },
    "& .mainTitle": {
        margin: "auto",
        marginLeft: "2%",
        height: "40px",
        marginBottom: "2%",
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "30px",
        fontWeight: 900,
        alignItems: "center",
        display: "flex",
    },
    "& .mainWrapper": {
        height: "auto",
        width: "60%",
        display: "flex",
        "@media (max-width: 1400px)": {
            width: "82%",
        },
        "@media (max-width: 1000px)": {
            width:"80%",
            flexDirection: "column",
        },
    },
    "& .itemTitle": {
        width: "87%",
        textAlign: "center",
        fontWeight: 900,
        fontSize: "18px",
        textTransform : "uppercase",
        fontFamily: "Nexa-Heavy",
        height : "76px"
    },
    "& .lowerContent": {
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: "2%",
    },
    "& .scrollHostMonetization": {
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        paddingRight: "40px",
        overflow: "hidden",
        scrollbarWidth: "thin"
    },
    "& .upperContent": {
        width: "100%",
        marginTop: "2%"
    },   
    "& .desc": {
        margin: "auto",
        marginTop: "1%",
        textAlign: "center",
        fontFamily: "Nexa-Regular",
        fontSize: "15px",
    },
    "& .desc1": {
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
    },
    "& .title": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontSize: "30px",
        fontWeight: 900,
        textTransform: "capitalize"
    },
    "& .subContainer": {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        width: "95%",
        height: "auto",
        backgroundColor: "#44403C",
        borderRadius: "15px"
    },
    "& .container": {
        color: "white",
        height: "auto",
        margin: "auto",
        marginBottom: "3%",
    },
    "& .image": {
        display: "flex",
        height: "40%",
        width: "80%",
        paddingTop: "5%",
        boxSizing: "content-box",
        margin: "auto",
    },
})
// Customizable Area End
