// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S { 
  annualPrice : string;
  monthlyPrice : string;
  forgotPasswordResponse : Object;
}

export interface SS {
  id: any;  
}

export default class HostPaymentPlanController extends BlockComponent<
  Props,
  S,
  SS
> {  
   fetchSubsApiCallId = ""
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = { 
      monthlyPrice : "",
      annualPrice : ""  ,
      forgotPasswordResponse :{}   
    };
  } 

  async receive(from: string, message: Message) {    
    this.handleResForFetchSubsApi(from,message);     
  }

  openLink = (url: string) => {
    if (typeof window !== "undefined") {
      window.open(url, "_blank");
    }
  }; 
  async componentDidMount() {
    this.fetchSubscription()
  }

  fetchSubscription =async () => {     
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
       
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchSubsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchSubsPlan
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForFetchSubsApi = async (from: string, message: Message) => {    
    if (this.fetchSubsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        
      if (responseJson.data) {
        console.log("responseData",responseJson.data)
        responseJson.data.forEach((item:any) => {
          const { name, price } = item.attributes;
          if (name.includes("Annual Plan")) {
            this.setState({
              annualPrice : price
            })
          } else if (name.includes("Monthly Plan")) {
            this.setState({
              monthlyPrice : price
            })
          }
        });
        this.setState({ forgotPasswordResponse:responseJson.data });               
      }
    }
  };

}
// Customizable Area End
