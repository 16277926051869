// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    TextField
} from "@mui/material";
import ChooseFeatureSubscriptionTierController, {
    Props,
} from "./ChooseFeatureSubscriptionTierController";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { checkbox, checkboxTicked, goBack } from "./assets";
import EditIcon from '@mui/icons-material/Edit';
import { ResolveCondition, handleNavigation } from "../../utilities/src/CustomHelpers";

export default class ChooseFeatureSubscriptionTier extends ChooseFeatureSubscriptionTierController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id='1' />
                <StyledWrapperSponsoredContentPartnership>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box className="mainTitle" id="sponsoredContent" style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "10px", marginLeft: "3%" }}>
                            <img
                                src={goBack}
                                alt="goBack"
                                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                                data-test-id="navigate"
                                onClick={() => { handleNavigation("HostMonetizationSetup", this.props) }}
                            />
                            Back
                        </Box>
                        <Box className="upperBtn" style={{ marginLeft: "auto", marginRight: "10px" }}>
                            Re-Set Up Monetization
                        </Box>
                        <Box className="upperBtn" style={{ marginRight: "5%" }} id="sponsoredContent">
                            Save For Later
                        </Box>
                    </Box>
                    <Box className="scrollHostMonetization" id="sponsoredContent">
                        <Box className="container">
                            <Box className="subContainer" id="sponsoredContent">
                                <Box className="upperContent">
                                    <Box className="title">Choose Features for Your Subscription Tiers</Box>
                                    <Box className="desc">Select the features you want to include in your membership tiers. You can choose multiple features in each tier. You can also remove tiers and have minimum of 2.</Box>
                                </Box>
                                <Box>

                                </Box>
                                <Box className="lowerContent">
                                    {this.state.data.map((item: any, index: number) => (
                                        <Box className="tiers" key={index}>
                                            {!this.state.editFlags[index] ? (
                                                <>
                                                    <Box className="icon">
                                                        <EditIcon onClick={() => this.toggleEditMode(index)} />
                                                    </Box>
                                                    <Box className="heading">{item.attributes.name}</Box>
                                                    <Box className="price">
                                                        {ResolveCondition(
                                                            item.attributes.price == null,
                                                            "Free",
                                                            `$${item.attributes.price}`
                                                        )}
                                                    </Box>
                                                    <Box className="listPoints">
                                                        <ul>
                                                            {item.attributes.features.map((item1: any, featureIndex: number) => (
                                                                <li className="listing" key={featureIndex}>
                                                                    {item1}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Box className="heading" style={{ marginTop: "22%" }}>
                                                        {item.attributes.name}
                                                    </Box>
                                                    <TextField
                                                        className="editablePrice"
                                                        value={`$${this.state.editablePrice[index] || ""}`}
                                                        onChange={(e) => this.handlePriceChange(index, e.target.value)}
                                                        placeholder="Enter price"
                                                        variant="outlined"
                                                        sx={{
                                                            "& .MuiOutlinedInput-root": {
                                                                "& fieldset": {
                                                                    border: "none",
                                                                },
                                                                "& input": {
                                                                    textAlign: "center",
                                                                    paddingTop: "2.5px",
                                                                    fontFamily: "Nexa-Heavy",
                                                                    fontSize: "50px",
                                                                    color: "white",
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    <Box className="listPoints">
                                                        <ul>
                                                            {item.attributes.features.map((feature: any, featureIndex: number) => {
                                                                const key = `${index}-${featureIndex}`;
                                                                const isChecked = this.state.checkedFeatures[key] || false;

                                                                return (
                                                                    <li
                                                                        className="listing"
                                                                        key={featureIndex}
                                                                        style={{ listStyle: "none", display: "flex" }}
                                                                    >
                                                                        <img
                                                                            src={isChecked ? checkboxTicked : checkbox} 
                                                                            alt="checkbox"
                                                                            style={{ marginLeft: "-25px", marginRight: "10px" }}
                                                                        />
                                                                        {feature}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </Box>


                                                </>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                                <Button className="btn"
                                    disabled={true}
                                    data-test-id="dataNavigations"
                                    onClick={() => {
                                        handleNavigation("HostMonetizationTemplate", this.props)
                                    }}
                                >Continue</Button>

                                <Button className="btn"
                                    style={{ marginBottom: "4%", marginTop: "0px" }}                                    
                                >Save</Button>

                            </Box>
                        </Box>
                        <Footer navigation={this.props.navigation} id={"1"} />
                    </Box>
                </StyledWrapperSponsoredContentPartnership>
            </>

        );
    }

}
const StyledWrapperSponsoredContentPartnership = styled("div")({
    background: "black",
    paddingRight: "3%",
    paddingLeft: "calc(6% + 100px)",
    "& *": {
        boxSizing: "border-box",
    },
    "& .editablePrice": {
        background: "#6F6E6D",
        width: "100%",
        height: "71px",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontSize: "50px",
        marginTop: "12%",
    },
    "& .listing": {
        color: "white",
        marginLeft: "15px",
        fontFamily: "Nexa-Regular",
        fontSize: "18px",
        marginBottom: "12px"
    },
    "& .price": {
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontSize: "50px",
        marginTop: "12%"
    },
    "& .heading": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontSize: "25px",
        textAlign: "center",
        paddingTop: "2%"
    },
    "& .icon": {
        display: "flex",
        justifyContent: "center",
        marginTop: "15%"
    },
    "& .tiers": {
        background: "#1C1917",
        borderRadius: "15px",
        height: "425px",
        width: "335px",
        marginRight: "15px",
        marginTop: "2%",
    },
    "& .upperBtn": {
        width: "auto",
        height: "26px",
        borderRadius: "8px",
        fontWeight: 400,
        color: "white",
        fontSize: "16px",
        fontFamily: "Nexa-Heavy",
        background: "#312F2D",
        marginRight: "26px"
    },
    "& .btn": {
        height: "56px",
        margin: "auto",
        cursor: "pointer",
        marginTop: "3%",
        marginBottom: "2%",
        color: "white",
        background: "#B2B3B3",
        width: "30%",
        borderRadius: "8px",
        justifyContent: "center",
        display: "flex",
        fontWeight: 900,
        alignItems: "center",
        fontFamily: "Nexa-Heavy",
        fontSize: "14px",
        textTransform: "capitalize",
        "&:hover": {
            background: "#B2B3B3",
        },
        "&:disabled": {
            color: "white",
        }
    },
    "& .btnColor": {
        background: "#FF0807",
        "&:hover": {
            background: "#FF0807",
        }
    },
    "& .mainTitle": {
        margin: "auto",
        marginLeft: "2%",
        height: "40px",
        marginBottom: "2%",
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "30px",
        fontWeight: 900,
        alignItems: "center",
        display: "flex",
    },
    "& .mainWrapper": {
        height: "auto",
        width: "47%",
        display: "flex",
        "@media (max-width: 1400px)": {
            width: "62%",
        },
        "@media (max-width: 1000px)": {
            width: "90%",
        },
        "@media (max-width: 800px)": {
            flexDirection: "column",
        }
    },
    "& .itemTitle": {
        width: "87%",
        textAlign: "center",
        fontWeight: 900,
        fontSize: "18px",
        fontFamily: "Nexa-Heavy",
    },
    "& .lowerContent": {
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    "& .scrollHostMonetization": {
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        paddingRight: "40px",
        overflow: "hidden",
        scrollbarWidth: "thin"
    },
    "& .upperContent": {
        width: "100%",
        marginTop: "2%"
    },
    "& .desc": {
        margin: "auto",
        marginTop: "1%",
        textAlign: "center",
        fontFamily: "Nexa-Regular",
        fontSize: "16px",
        marginLeft: "5%",
        marginRight: "5%"
    },
    "& .desc1": {
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
    },
    "& .title": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontSize: "30px",
        fontWeight: 900,
        textTransform: "capitalize"
    },
    "& .subContainer": {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        width: "95%",
        height: "auto",
        backgroundColor: "#44403C",
        borderRadius: "15px"
    },
    "& .container": {
        color: "white",
        height: "auto",
        margin: "auto",
        marginBottom: "3%",
    },
    "& .image": {
        display: "flex",
        height: "40%",
        width: "80%",
        paddingTop: "5%",
        boxSizing: "content-box",
        margin: "auto",
    },
})
// Customizable Area End
