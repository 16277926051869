// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  isLinkValid: boolean,
  podcastData: Podcast,
  openFirstDialog: boolean,
  openSecondDialog: boolean,
  selectedFile: File | null,
  imagePreviewUrl: string,
  tempLogoUrl: string,
  openFinishSetupDialog: boolean,
  storageData: Object,
}

export interface SS {
  id: any;
}

interface Podcast {
  podcastName: string,
  podcastCover: string,
  description: string,
  backgroundColor: string,
  secondaryColor: string,
  textColor: string,
  buttonColor: string
}

export default class TemplatePreviewWithLogoController extends BlockComponent<
  Props,
  S,
  SS
> {
  createPodcastCallId: string = "";
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isLinkValid: true,
      podcastData: {
        podcastName: '',
        podcastCover: '',
        description: '',
        backgroundColor: '',
        secondaryColor: '',
        textColor: '',
        buttonColor: '',
      } as Podcast,
      openFirstDialog: true,
      openSecondDialog: false,
      selectedFile: null,
      imagePreviewUrl: "",
      tempLogoUrl: "",
      openFinishSetupDialog: false,
      storageData: {},
    };
  }

  async componentDidMount() {
    // Customizable Area Start
    let feedName = await getStorageData('feedName');
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')))
    let storageData = storageKey ? (await getStorageData(storageKey)) : null;
    let data = storageData !== null ? JSON.parse(storageData) : {};
    let logoUrl = data['logoUrl'];
    this.setState({
      imagePreviewUrl: logoUrl || '',
      podcastData: {
        ...this.state.podcastData,
        podcastName: data['podcastName'] || 'The True Podcast',
        podcastCover: data['podcastCover'],
        description: data['description'] || 'COST OF WINNING',
        backgroundColor: data['backgroundColor'] || '#44403C',
        secondaryColor: data['secondaryColor'] || '#44403C',
        textColor: data['textColor'] || '#fff',
        buttonColor: data['buttonColor'] || "#FF0807"
      }
    });
    // Customizable Area End
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleCreatePodcastResponse(from, message);
    // Customizable Area End
  }

  handleCloseFirstDialog = () => {
    this.setState({ openFirstDialog: false });
  };

  handleOpenSecondDialog = () => {
    this.setState({
      openFirstDialog: false,
      openSecondDialog: true
    });
  };

  handleCloseSecondDialog = () => {
    this.setState({ openSecondDialog: false });
  };
  handleDoneClick = () => {
   let imageUrl = this.state.selectedFile ? URL.createObjectURL(this.state.selectedFile) : '';
   this.setState({imagePreviewUrl: imageUrl});
   this.setState({ openSecondDialog: false });
  }
  handleCancelClick = () => {
    this.setState({selectedFile: null, imagePreviewUrl: ""})
    this.setState({ openSecondDialog: false });
  }
  handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      let imageUrl = URL.createObjectURL(file);
      this.setState({ selectedFile: file, tempLogoUrl: imageUrl });
      const base64String = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            resolve(reader.result as string);
          }
        };
        reader.readAsDataURL(file);
      });
      await this.updateFeedData(base64String);
    };
  };
  handleCloseFinishSetupDialog = () => {
    this.setState({ openFinishSetupDialog: false });
  }
  updateFeedData = async (url: string) => {
    let feedName = await getStorageData('feedName');
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')))
    let storageData = storageKey ? (await getStorageData(storageKey)) : null;
    let data = storageData !== null ? JSON.parse(storageData) : {};
    data['logoUrl'] = url;
    storageKey && setStorageData(storageKey, JSON.stringify(data));

  }
  createPodcastApi = async () => {
    let token = await getStorageData("token");
    let feedName = await getStorageData("feedName");
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', 'noRssData');
    let storageData = storageKey && await getStorageData(storageKey);
    let data = await storageData && JSON.parse(storageData);
    this.setState({ storageData: data })
    const header = {
      token: JSON.parse(token),
    };
    let podcastCoverImage = this.base64UrlToFile(data.podcastCover);
    let formData = new FormData();
    if (feedName == "noRss") {
      formData.append('podcast[name]', data.podcastName);
      formData.append('podcast[description]', data.description);
      formData.append('podcast[location]', data.podcastLocation);
      formData.append('podcast[explicit_lang]', ResolveCondition(data.explicitLanguage === 'N', "false", "true") || "false");
      formData.append('podcast[adult]', ResolveCondition(data.restriction === '1', "true", "false") || "false");
      formData.append('podcast[timezone]', JSON.stringify(data.timezone));
      formData.append('podcast[podcast_time]', JSON.stringify(data.publishTime));
      formData.append('podcast[host_type]', data.showHosting);
      formData.append('podcast[category_ids][]', data.podcastCategory);
      formData.append('podcast[sub_category_ids][]', data.podcastSubCategory);
      formData.append('podcast[hosts_attributes][0][first_name]', data.hostFirstName);
      formData.append('podcast[hosts_attributes][0][last_name]', data.hostLastName);
      formData.append('podcast[hosts_attributes][0][email]', data.hostEmail);
      formData.append('podcast[hosts_attributes][0][host_location]', data.hostLocation);
      formData.append('podcast[color_scheme_attributes][bg_color]', data.backgroundColor);
      formData.append('podcast[color_scheme_attributes][secondry_color]', data.secondaryColor);
      formData.append('podcast[color_scheme_attributes][text_color]', data.textColor);
      formData.append('podcast[color_scheme_attributes][button_color]', data.buttonColor);
      formData.append('podcast[color_scheme_attributes][template_type]', data.selectedTemplate);
      this.state.selectedFile && formData.append('podcast[color_scheme_attributes][logo]', this.state.selectedFile);
      podcastCoverImage && formData.append('podcast[cover_image]', podcastCoverImage);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createPodcastCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPodcast
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCreatePodcastResponse = async (from: string, message: Message) => {
    if (this.createPodcastCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.setState({ openFinishSetupDialog: true });
        setStorageData("podcast_id", responseJson.data.id);
        let feedName = await getStorageData('feedName');
        let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')));
        await removeStorageData("feedName");
        storageKey && await removeStorageData(storageKey);
      }
    }
  };
  base64UrlToFile = (base64Url: string) => {
    const [header, base64Data] = base64Url.split(',');
    const mimeTypeMatch = header.match(/data:([^;]+);base64/);
    const mimeType = mimeTypeMatch ? mimeTypeMatch[1] : 'application/octet-stream';
    const binaryData = atob(base64Data);
    const byteNumbers = new Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    return new File([blob], 'filename', { type: mimeType });
  }
  handleFinishSetup = () => {
    this.setState({ openFinishSetupDialog: true })
    this.createPodcastApi();
  }
}
// Customizable Area End
