import React from "react";

// Customizable Area Start
import { logo, ads1, ads2, ads3, poster } from "./assets"

const WIDE_SCREENS =  typeof window !== 'undefined' && window.innerWidth > 1500
// Customizable Area End

import VideosController, { Props, configJSON } from "./VideosController";

export default class Videos extends VideosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={webStyle.mainWrapper}>
        <div style={webStyle.logo}>
          <img src={logo} />
        </div>
        <div style={webStyle.mainContent}>
          <div style={webStyle.videoPlayer}>
            <video 
              height={"auto"} 
              width={WIDE_SCREENS ? "1280px" : "960px"}  
              controls 
              poster={poster}>
              <source src={configJSON.exampleVideoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div style={webStyle.videoAds}>
            <img src={ads1} width={WIDE_SCREENS ? " 245px" : "311px"} height="auto" />
            <img src={ads2} width={WIDE_SCREENS ? " 245px" : "311px"} height="auto" />
            {WIDE_SCREENS && <img src={ads3} />}
          </div>
        </div>
        <div style={webStyle.audioPlayer}>

        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    paddingTop: "2%",
    paddingLeft: WIDE_SCREENS ? "6%" : 0,
    paddingRight: "2%"
  },
  logo: {
    display: "flex",
    justifyContent: "start",
    selfAlign: "start",
    paddingBottom: "2%",
    paddingLeft: !WIDE_SCREENS ? "6%" : 0,
  },
  mainContent: {
    display: 'flex',
    gap: 20,
    marginLeft: "6%",
    justifyContent: "space-between",
  },
  audioPlayer: {

  },
  videoPlayer: {

  },
  videoAds: {
    display: "flex",
    flexDirection: "column", 
    gap: 15,
    alignItems: "center",
    justifyContent: "center"
  } as React.CSSProperties
};
// Customizable Area End
