// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button, Grid, TextField
} from "@mui/material";
import HostMonetizationOfferingConsultancyController, {
    Props,
} from "./HostMonetizationOfferingConsultancyController";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { goBack, offeringConsulting } from "./assets";
import {  handleNavigation } from "../../utilities/src/CustomHelpers";
import * as Yup from "yup";
import { Formik, Form } from "formik";

export default class HostMonetizationSellingDigitalProducts extends HostMonetizationOfferingConsultancyController {
    constructor(props: Props) {
        super(props);
    }

    validationSchema = Yup.object().shape({
        offeringConsultancyWebsite: Yup.string().required('Required'),
    })
    render() {
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id='1' />
                <StyledWrapperSponsoredPost>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box className="mainTitle" id="sellingDigitalProducts" style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "10px", marginLeft: "60px" }}>
                            <img
                                id="sellingDigitalProducts"
                                src={goBack}
                                alt="goBack"
                                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                                data-test-id="handleNavigation"
                                onClick={() => { handleNavigation("HostMonetizationTemplate", this.props) }}
                            />
                            Back
                        </Box>
                        <Box className="upperBtn" style={{ marginLeft: "auto", marginRight: "10px" }}>
                            Re-Set Up Monetization
                        </Box>
                        <Box className="upperBtn" style={{ marginRight: "70px" }} id="sellingDigitalProducts">
                            Save For Later
                        </Box>
                    </Box>
                    <Box className="container">
                        <Box className="subContainer">
                            <Box>
                                <img 
                                src={offeringConsulting}
                                 alt="sellingDigitalProducts" style={{ width: "100%", height: "238px" }} />
                            </Box>
                            <Box style={{
                                borderRadius: "0px 0px 15px 15px",
                                backgroundColor: "#44403C",
                            }}>
                                <Box className="upperContent">
                                    <Box className="title">Offering Consulting or Coaching Services</Box>
                                    <Box className="secTitle">Please enter the URL of your website where you will be offering consulting or coaching services</Box>
                                </Box>
                                <Formik
                                    initialValues={{
                                        offeringConsultancyWebsite: '',
                                    }}
                                    validationSchema={this.validationSchema}
                                    validateOnMount={true}
                                    onSubmit={async (values) => {
                                        console.log(values, "values");
                                        handleNavigation("HostMonetizationAffiliateMarketing",this.props)
                                    }}
                                    data-test-id="submitBtn"
                                >
                                    {({ handleChange, handleSubmit, values, errors }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Grid
                                                className="fields"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "33%",
                                                    margin: "auto",
                                                    marginTop: "2%"
                                                }}
                                            >
                                                <Box style={{ marginBottom: "1%" }}>
                                                    <label className="label">
                                                        Website URL
                                                    </label>
                                                </Box>

                                                <TextField
                                                    id="outlined-basic"
                                                    name="offeringConsultancyWebsite"
                                                    value={values.offeringConsultancyWebsite}
                                                    onChange={handleChange}
                                                    sx={{
                                                        width: "100%",
                                                        marginTop: "2%",
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#CBD5E1",
                                                                borderRadius : "8px"
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#CBD5E1",
                                                                borderRadius : "8px"
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#CBD5E1",
                                                                borderRadius : "8px"
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: "#CBD5E1",
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            color: "#CBD5E1",
                                                            backgroundColor: "black",
                                                            borderRadius : "8px",
                                                            "&:-webkit-autofill": {
                                                                backgroundColor: "black !important",
                                                                WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                WebkitTextFillColor: "#CBD5E1 !important",
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Button
                                                className={errors && Object.keys(errors).length > 0 ? "btn" : "btn buttonColor"}
                                                disabled={errors && Object.keys(errors).length > 0 ? true : false}
                                                data-test-id="submitBtn"
                                                type="submit"

                                            >Finish setting up Digital Products</Button>
                                        </Form>
                                    )}
                                </Formik>

                            </Box>
                        </Box>
                    </Box>
                </StyledWrapperSponsoredPost>
            </>

        );
    }

}
const StyledWrapperSponsoredPost = styled("div")({
    background: "black",
    paddingRight: "3%",
    paddingLeft: "calc(6% + 100px)",
    "& *": {
        boxSizing: "border-box",
    },
    "& .secTitle": {
        fontFamily: "Nexa-Regular",
        fontWeight: 100,
        fontSize: "16px",
        textAlign: "center",
        paddingTop: "1.5%",
        paddingBottom: "1%"
    },
    "& .upperBtn": {
        width: "auto",
        borderRadius: "8px",
        height: "26px",
        color: "white",
        fontWeight: 400,
        fontFamily: "Nexa-Heavy",
        fontSize: "16px",
        marginRight: "26px",
        background: "#312F2D",
    },
    "& .label": {
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "14px",
        fontWeight: 700,
    },
    "& .btn": {
        margin: "auto",
        height: "56px",
        marginTop: "3%",
        cursor: "pointer",
        color: "white",
        marginBottom: "2%",
        width: "30%",
        background: "#B2B3B3",
        justifyContent: "center",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        textTransform: "capitalize",
        fontSize: "14px",
        "&:hover": {
            background: "#B2B3B3",
        },
        "&:disabled": {
            color: "white",
        }
    },
    "& .buttonColor": {
        background: "#FF0807",
        "&:hover": {
            background: "#FF0807",
        }
    },
    "& .mainTitle": {
        margin: "auto",
        marginLeft: "2%",
        height: "40px",
        marginBottom: "2%",
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "30px",
        fontWeight: 900,
        alignItems: "center",
        display: "flex",
    },
    "& .upperContent": {
        width: "100%",
        marginTop: "4%",
        marginBottom: "2%"
    },
    "& .title": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        fontSize: "30px",
        fontWeight: 900,
        textTransform: "capitalize"
    },
    "& .subContainer": {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        width: "95%",
        height: "auto",
        borderRadius: "15px",
    },
    "& .container": {
        color: "white",
        height: "auto",
        margin: "auto",
        marginBottom: "3%",
    },
})
// Customizable Area End