import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Link } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
// Customizable Area End

import RSSFeedsController, { Props, configJSON } from "./RSSFeedsController";

// Customizable Area Start
// Customizable Area End

export default class RSSFeeds extends RSSFeedsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">
                {configJSON.titleText}
                {this.state.data?.title}
              </Typography>
              {this.state.data?.items?.map((item: any) => {
                return (
                  <Box minWidth={"sm"} style={webStyle.rssWrapper}>
                    <Box style={webStyle.imgContent}>
                      <img
                        src={item?.enclosures[0]?.url}
                        style={webStyle.rssImg}
                      />
                    </Box>
                    <Box style={webStyle.rssContent}>
                      <Link
                        data-test-id="btnOpenURL"
                        href={item?.links[0]?.url}
                        target="_blank"
                        variant="h6"
                        style={webStyle.rssTitle}
                      >
                        {item?.title}
                      </Link>
                      <Typography variant="h6" style={webStyle.description}>
                        {item?.description}
                      </Typography>
                      <Typography variant="h6" style={webStyle.rssDate}>
                        {item?.published}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  rssWrapper: {
    display: "flex",
    borderBottom: "1px solid #D3D3D3",
    padding: 15,
  },
  imgContent: {
    width: 80,
    marginRight: 10,
  },
  rssImg: {
    maxWidth: "100%",
    marginTop: 5,
  },
  rssContent: {
    flex: 1,
    width: "100%",
  },
  rssTitle: {
    fontSize: 14,
    color: "#2196F3",
  },
  description: {
    fontSize: 12,
  },
  rssDate: {
    fontSize: 12,
    color: "#808080",
  },
};
// Customizable Area End
