export const bell = require("../assets/bell.svg").default
export const envelope = require("../assets/envelope.svg").default
export const logo = require("../assets/logo.svg").default
export const partner = require("../assets/partner.svg").default
export const search = require("../assets/search.svg").default
export const activeResident = require("../assets/active_resident.svg").default
export const userProfile = require("../assets/user-profile.png");
export const activeHost = require("../assets/active_host.svg").default
export const resident = require("../assets/resident.svg").default
export const mic = require("../assets/mic.svg").default
export const dashboard = require("../assets/dashboard.svg").default
export const library = require("../assets/library.svg").default
export const playlist = require("../assets/playlist.svg").default
export const social = require("../assets/social.svg").default
export const activeDiscover = require("../assets/activeDiscover.svg").default
export const activeDashboard = require("../assets/activeDashboard.svg").default
export const activeLibrary = require("../assets/activeLibrary.svg").default
export const activeSocial = require("../assets/activeSocial.svg").default
export const activePlaylist = require("../assets/activePlaylist.svg").default
export const discover = require("../assets/discover.svg").default
export const becomeHostActive = require("../assets/becomeHost.svg").default
export const nonActiveBecomeHost = require("../assets/host.svg").default
export const myChannel = require("../assets/myChannel.svg").default
export const activeMyChannel = require("../assets/activeMyChannel.svg").default
export const build = require("../assets/build.svg").default
export const activeBuild = require("../assets/activeBuild.svg").default
export const engage = require("../assets/engage.svg").default
export const activeEngage = require("../assets/activeEngage.svg").default
export const hostSettings = require("../assets/hostSettings.svg").default
export const activeHostSettings = require("../assets/activeHostSettings.svg").default
export const activeMonetize = require("../assets/activeMonetize.svg").default
export const monetize = require("../assets/monetize.svg").default
export const integrations = require("../assets/integrations.svg").default
