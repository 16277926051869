// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
} from "@mui/material";
import HostLoginController, {
    Props,
} from "./HostLoginController";
import { hostBg } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
const MIN_WINDOW_HEIGHT = 900

export default class HostLogin extends HostLoginController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id='1' />
                <div style={webStyles.container}>
                <StyledWrapperSignUp>
                <Box id="loginHost">
                <Box className="container">
                    <img src={hostBg} alt="hostBg" className="image"/>
                    <Box className="subContainer">
                        <Box className="title">Become A Host</Box>
                        <Box className="desc">Start the journey to build, engage and monetize your audience</Box>
                        <Button className="btn" data-test-id = "buttonClick" onClick={()=>{handleNavigation("HostPaymentPlan",this.props)}}>Begin to Setup My Channel</Button>                        
                    </Box>                    
                </Box>
                </Box>
                </StyledWrapperSignUp>
                <Footer navigation={this.props.navigation} id={"1"} />
                </div>
            </>
           
        );
    }

}
const StyledWrapperSignUp = styled("div")({
    background: "black",
    paddingRight: "5%",
    paddingLeft: "calc(6% + 135px)",
    "& *": {
        boxSizing: "border-box",
    },
    "& .scrollHostLogin":{
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        height: "34rem",
        paddingRight: "40px", 
        overflow: "hidden",     
        scrollbarWidth: "thin", 
    },
    "& .btn" :{
        cursor : "pointer",
        margin : "auto",
        marginBottom : "5%",
        background : "#FF0807",
        color : "white",
        borderRadius : "8px",
        height : "56px",
        width : "272px",
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        fontWeight : 900,
        fontSize : "14px",
        fontFamily : "Nexa-Heavy",
        textTransform : "capitalize",
        "&:hover" : {
            background : "#FF0807",        
        }
    },
    "& .desc":{
        margin : "auto",
        textAlign : "center",
        fontFamily : "Nexa-Regular",
        fontSize : "16px",
        fontWeight : "bold"
    },
    "& .title":{
        color : "white",
        fontFamily : "Nexa-Heavy",
        textAlign : "center",
        fontSize : "30px",
        fontWeight : 900
    },
    "& .subContainer":{
        margin : "auto",
        display:"flex",
        flexDirection : "column",
        width:"80%",
        height : "48%",
        marginTop : "2%"
    },
    "& .container":{
    color : "white",
    height:"auto",
    margin:"auto",
    background:"#44403C",
    },
    "& .image":{
        display:"flex",
        height : "50%",
        width : "80%",
        paddingTop : "5%",
        boxSizing: "content-box",
        margin:"auto",       
    }
})
const webStyles = {
    
    container: {
        height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
        overflowY: "scroll" as const,
        scrollbarWidth: "thin", 
        scrollbarColor: "red gray", 
        "&::-webkit-scrollbar": {
          width: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "red", 
          borderRadius: "10px", 
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "gray", 
        },
        "&::-webkit-scrollbar-button": {
            display: "none", 
          },
      } as const
    }      
// Customizable Area End
