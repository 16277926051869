import React from "react";

// Customizable Area Start
import { Close } from '@mui/icons-material';
import Loader from "../../../components/src/Loader.web"
const image = require("../../../components/src/view_overlay.jpg").default

// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <div style={styles.container}>
        <Loader loading={this.state.isLoading} />
        <div style={styles.overlay}>
          <div style={styles.content}>
            <div style={styles.iconWrapper}>
              <Close 
                data-test-id="closeIcon"
                style={{color: "#fff"}} 
                onClick={() => this.props.navigation.goBack()} />
            </div>
            <div style={styles.title}>Terms and Conditions</div>
            <div style={{ color: "white" }}
              dangerouslySetInnerHTML={{ __html: this.state.termsCondsDescription }}
            />
            <button 
              data-test-id="closeButton"
              onClick={() => this.props.navigation.goBack()} 
              style={styles.button}>
              Close
            </button>
          </div>
        </div>
      </div>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  container: {
    height: "100vh",
    backgroundColor: "#312F2D",
    overflow: "hidden",
    backgroundImage: `url(${image})`
  },
  iconWrapper: {
    display: "flex",
    justifyContent: 'flex-end',
    alignSelf: "flex-end",
    paddingTop: "20px",
  },
  title: {
    display: "flex",
    alignSelf: "flex-start",
    color: "#fff",
    fontWeight: 900 as const,
    fontFamily: "Poppins",
    fontSize: "50px",
    lineHeight: '32px',
    paddingTop: "3%"
  },
  overlay: {
    margin: "4% 3% 3% 3%",
    backgroundColor: "#312F2D",
    height: "calc(100vh - 15%)",
    overflow: "scroll",
    borderRadius: 8,
  },
  content: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px"
  },
  button: {
    backgroundColor: "#FF0807",
    padding: "6px 10px",
    border: "none", 
    cursor: "pointer",
    color: "#fff",
    fontSize: "16px" as const,
    width: "222px",
    borderRadius: 8,
    height: "32px",
    fontWeight: 600,
    fontFamily: "Poppins",
    marginTop: "50px",
    marginBottom: "30px",
  }
}
// Customizable Area End
