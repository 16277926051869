import {Message} from "../../../framework/src/Message";
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
  import { runEngine } from "../../../framework/src/RunEngine";

  export const ResolveCondition = (condition: boolean, truthy: string | undefined, falsy: string | undefined): string | undefined => {
    return condition ? truthy : falsy;
};

export const handleNavigation = (pathname: string, props?: unknown, param?: string) => {    
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), pathname);
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    param && message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    runEngine.sendMessage(message.id, message)
};