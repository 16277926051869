import { FormikErrors, FormikTouched } from "formik";

export const ConditionalRender = (
    errors: FormikErrors<{ [field: string]: any }>,
    touched: FormikTouched<{ [field: string]: any }>,
    key: keyof typeof errors,
    element: React.ReactNode
  ) => {
    return errors[key] && touched[key] ? element : null;
  };
