import React from "react";

// Customizable Area Start

import { styled, Box, Grid, Typography, Button, IconButton, Avatar, Divider } from "@mui/material";
import { LocationOn, MailOutline, LockOutlined, FacebookRounded, LinkedIn, X, Instagram, ContentCopy, EditOutlined, Block } from '@mui/icons-material';
import { AccountsTopImage, personImage, artIcon, businessIcon, comedyIcons, eductaionIcon, goBack } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { handleNavigation } from "../../utilities/src/CustomHelpers";

// Customizable Area End

import AccountSettingsController, {
  Props
} from "./AccountSettingsController";

export default class AccountSettings extends AccountSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu navigation={this.props.navigation} id='1' />
        <StyledWrapperSetting>
          <Box display="flex" alignItems="center" mb={3}>
            <img onClick={this.handleGoBack} src={goBack} alt="go Back" style={{ marginBottom: '20px', marginLeft: "12%", width: "2%" }} />
            <Typography variant="h4" sx={{ color: '#fff', marginBottom: '20px', marginLeft: "2%", fontWeight: "bold", fontFamily: "Nexa-Heavy", fontSize: "30px" }}>
              Account
            </Typography>
          </Box>
          <Grid spacing={3} container>
            <Box className="account-settings-container-1">
              <Box className="outer-box-container">
                <img src={AccountsTopImage} className="container-one-top-image" />
                <Box className="profile-container">
                  <Avatar alt="User Image" src={personImage} className="profile-avatar" />
                  <Box className="profile-info">
                    <Box className="profile-info-container" display="flex" flexDirection="row" alignItems="center" style={{ gap: "50px" }}>
                      <Box className="profile-info-1" display="flex" flexDirection="column">
                        <Typography variant="h5" className="profile-name">
                          {this.state.firstName} {this.state.lastName}
                        </Typography>
                        <br/>
                        <Typography variant="subtitle1" className="profile-location">
                          <IconButton><LocationOn style={{ color: "#fff" }} /></IconButton>{this.state.location}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" style={{ gap: "10px" }} flexGrow={1} justifyContent="flex-end">
                        <Button variant="contained" color="primary" className="profile-edit-btn" onClick={() => handleNavigation('EditProfile', this.props)}>
                          <IconButton style={{ marginLeft: "-10%" }}><EditOutlined style={{ color: "#fff" }} /></IconButton>Edit Profile
                        </Button>
                        <Button variant="contained" className="profile-inbox-btn" onClick={() => handleNavigation('MyInbox', this.props)} >
                          <IconButton style={{ marginLeft: "-10%" }}><MailOutline style={{ color: "#fff" }} /></IconButton>My Inbox
                        </Button>
                        <Button variant="contained" className="profile-blocked-btn">
                          <IconButton style={{ marginLeft: "-8%" }}><Block style={{ color: "#030303" }} /></IconButton>Blocked users
                        </Button>
                      </Box>
                    </Box>
                    <Box className="profile-info-2">
                      <Typography variant="body2" className="profile-bio1">
                        BIO
                        <Typography />
                        <Typography variant="body2" className="profile-bio2">{this.state.bio}</Typography>
                        <Typography variant="body2" className="profile-bio3">{this.state.dob}</Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={3}>
            <Box className="account-settings-container-2">
              <Box className="outer-box-container">
                <Box className="inner-box">
                  <IconButton className="inner-box-icon">
                    <MailOutline />
                  </IconButton>
                  <Box ml={2}>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>Email</Typography>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>{this.state.userEmail}</Typography>
                  </Box>
                  <Box flexGrow={1} display="flex" justifyContent="flex-end">
                    <IconButton>
                      <ContentCopy style={{ color: "white", cursor: "pointer" }} />
                    </IconButton>
                  </Box>
                </Box>
                <Divider sx={{ margin: "20px 0", backgroundColor: "transparent", "&.MuiDivider-root": { border: "1px solid black" } }} />
                <Box className="inner-box">
                  <IconButton className="inner-box-icon">
                    <LockOutlined />
                  </IconButton>
                  <Box ml={2}>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>Password</Typography>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>********</Typography>
                  </Box>
                  {/* <IconButton onClick={this.togglePasswordVisibility} style={{color: "#fff"}}>
                  {this.state.showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton> */}
                  <Box flexGrow={1} display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" className="profile-reset-password-btn" onClick={() => handleNavigation("ResetPassword", this.props)}>
                      Reset Password
                    </Button>
                  </Box>
                </Box>
                <Divider sx={{ margin: "20px 0", backgroundColor: "transparent", "&.MuiDivider-root": { border: "1px solid black" } }} />
                <Box className="inner-box">
                  <IconButton className="inner-box-icon">
                    <FacebookRounded style={{ color: "blue" }} />
                  </IconButton>
                  <Box ml={2}>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>Facebook</Typography>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>{this.state.socialMedia.facebook}</Typography>
                  </Box>
                </Box>
                <Divider sx={{ margin: "20px 0", backgroundColor: "transparent", "&.MuiDivider-root": { border: "1px solid black" } }} />
                <Box className="inner-box">
                  <IconButton className="inner-box-icon">
                    <LinkedIn style={{ color: "blue" }} />
                  </IconButton>
                  <Box ml={2}>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>LinkedIn</Typography>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>{this.state.socialMedia.linkedIn}</Typography>
                  </Box>
                </Box>
                <Divider sx={{ margin: "20px 0", backgroundColor: "transparent", "&.MuiDivider-root": { border: "1px solid black" } }} />
                <Box className="inner-box">
                  <IconButton className="inner-box-icon">
                    <X />
                  </IconButton>
                  <Box ml={2}>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>X</Typography>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>{this.state.socialMedia.x}</Typography>
                  </Box>
                </Box>
                <Divider sx={{ margin: "20px 0", backgroundColor: "transparent", "&.MuiDivider-root": { border: "1px solid black" } }} />
                <Box className="inner-box">
                  <IconButton className="inner-box-icon">
                    <Instagram />
                  </IconButton>
                  <Box ml={2}>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>Instagram</Typography>
                    <Typography style={{ color: "white", fontFamily: "Nexa-Heavy" }}>{this.state.socialMedia.instagram}</Typography>
                  </Box>
                  <Divider sx={{ margin: "20px 0", backgroundColor: "transparent", "&.MuiDivider-root": { border: "1px solid black" } }} />
                </Box>
                <Divider sx={{ margin: "20px 0", backgroundColor: "transparent", "&.MuiDivider-root": { border: "1px solid black" } }} />
                <Box style={{ height: "120px", marginTop: "16px", marginLeft: "40px", gap: "25px" }}>
                  <Typography style={{ color: "white", fontFamily: "Nexa-Heavy", marginLeft: "2%" }}>Favorite Podcast Categories</Typography>
                  <Box display="flex" alignItems="center" style={{ height: "46px", margin: "2%", gap: "70px" }}>
                    <Box display="flex" flexDirection="row" alignItems="center" style={{ marginRight: "3%" }}>
                      <Box className="fav-podcast-icon">
                        <img src={artIcon} alt="img_art" style={{ width: "30px", height: "29px", marginTop: "7px", marginLeft: "6px" }} />
                      </Box>
                      <Typography style={{ color: "white", marginLeft: "8px", fontFamily: "Nexa-Heavy" }}>Art</Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="center" style={{ marginRight: "3%" }}>
                      <Box className="fav-podcast-icon">
                        <img src={businessIcon} alt="img_art" style={{ width: "30px", height: "29px", marginTop: "7px", marginLeft: "6px" }} />
                      </Box>
                      <Typography style={{ color: "white", marginLeft: "8px", fontFamily: "Nexa-Heavy" }}>Business</Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="center" style={{ marginRight: "3%" }}>
                      <Box className="fav-podcast-icon">
                        <img src={comedyIcons} alt="img_art" style={{ width: "30px", height: "29px", marginTop: "7px", marginLeft: "6px" }} />
                      </Box>
                      <Typography style={{ color: "white", marginLeft: "8px", fontFamily: "Nexa-Heavy" }}>Comedy</Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="center" style={{ marginRight: "3%" }}>
                      <Box className="fav-podcast-icon">
                        <img src={eductaionIcon} alt="img_art" style={{ width: "30px", height: "29px", marginTop: "7px", marginLeft: "6px" }} />
                      </Box>
                      <Typography style={{ color: "white", marginLeft: "8px", fontFamily: "Nexa-Heavy" }}>Education</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </StyledWrapperSetting>
        <Footer id={"1"} navigation={this.props.navigation} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  height: "auto",
  paddingLeft: "calc(2% + 115px)",
  paddingRight: "5%",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .outer-heading": {
    fontWeight: "900",
    fontSize: "30px",
    marginLeft: "5px",
    color: "#fff",
  },
  "& .account-settings-container-1": {
    height: "auto",
    marginLeft: "13%",
    borderRadius: "8px",
    width: "80%",
    backgroundColor: "#44403C",
  },
  "& .account-settings-container-2": {
    backgroundColor: "#44403C",
    marginTop: "45px",
    borderRadius: "8px",
    width: "80%",
    height: "auto",
    marginLeft: "13%",
  },
  "& .outer-box-container": {
    margin: "0 auto",
    position: "relative",
    borderRadius: "8px",
  },
  "& .container-one-top-image": {
    width: "100%",
    height: "45%",
    objectFit: "cover",
    borderRadius: "0px 0px 32px 0px",
  },
  "& .profile-container": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "19px",
    borderRadius: "12px",
    width: "100%",
    marginTop: "0",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
  },
  "& .profile-avatar": {
    width: "136px",
    height: "136px",
    border: "3px solid white",
    marginBottom: "68px",
    position: "relative",
    marginTop: "-90px",
    zIndex: "1",
  },
  "& .profile-info-container": {
    display: "flex",
    alignItems: "center",
  },
  "& .profile-info": {
    flex: 1,
    marginRight: "24px",
  },
  "& .profile-info-1": {
    width: "256px",
    height: "auto",
    gap: "8px",
    marginLeft: "15px"
  },
  "& .profile-info-2": {
    width: "428px",
    height: "118px",
    gap: "8px",
    marginLeft: "15px"
  },
  "& .profile-name": {
    fontWeight: "bold",
    color: "#fff",
    fontFamily: "Nexa-Heavy",
    marginTop: '-20%'
  },
  "& .profile-location": {
    color: "#fff",
    fontWeight: "bold",
    marginLeft: '-7%',
    marginTop: '-6%',
    fontFamily: "Nexa-Heavy"
  },
  "& .profile-bio1": {
    marginTop: "-17%",
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Nexa-Heavy"
  },
  "& .profile-bio2": {
    marginTop: "2%",
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Nexa-Heavy"
  },
  "& .profile-bio3": {
    marginTop: "3%",
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Nexa-Heavy"
  },
  "& .profile-edit-btn": {
    textTransform: "capitalize",
    backgroundColor: "#FF0807",
    cursor: "pointer",
    width: "152px",
    height: "44px",
    borderRadius: "8px",
    fontWeight: "bold",
    padding: "10px 10px",
    alignSelf: "flex-end",
    marginTop: "15px",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: "#FF0807",
    },
  },
  "& .profile-inbox-btn": {
    backgroundColor: "#030303",
    width: "152px",
    height: "44px",
    textTransform: "capitalize",
    padding: "10px 16px",
    borderRadius: "8px",
    fontWeight: "bold",
    cursor: "pointer",
    alignSelf: "flex-end",
    marginTop: "5px",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: "#030303",
    },
  },
  "& .profile-blocked-btn": {
    width: "152px",
    height: "44px",
    backgroundColor: "#fff",
    textTransform: "capitalize",
    padding: "0px 2px",
    borderRadius: "8px",
    alignSelf: "flex-end",
    cursor: "pointer",
    marginTop: "5px",
    color: "black",
    fontWeight: "bold",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  "& .profile-reset-password-btn": {
    backgroundColor: "#FF5722",
    textTransform: "capitalize",
    width: "152px",
    height: "44px",
    borderRadius: "8px",
    padding: "10px 16px",
    fontWeight: "bold",
    cursor: "pointer",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: "#FF5722",
    },
  },
  "& .inner-box": {
    display: "flex",
    alignItems: "center",
    height: "48px",
    padding: "20px 16px 16px 24px",
    marginTop: "1%"
  },
  "& .inner-box-icon": {
    backgroundColor: "black",
    color: "white",
    width: "48px",
    height: "48px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "black",
    }
  },
  "& .fav-podcast-icon": {
    backgroundColor: "black",
    color: "white",
    width: "46px",
    height: "46px",
    borderRadius: "8px",
  }
});


// Customizable Area End
