import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, avatar_edit_state } from "./assets";
import React from 'react';
// import { ToastContainer,toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  userEmail: string;
  firstName: string;
  lastName: string;
  password: string;
  bio: string;
  dob: string; 
  location: string;
  socialMedia: {
    facebook: string;
    linkedIn: string;
    instagram: string;
    x: string;
  };
  favoritePodcasts: string[];
  showPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  profilePicture: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public fileInputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.fileInputRef = React.createRef<HTMLInputElement>();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      userEmail: "MichaelCJordan31@gmail.com",
      firstName: "Michael C.",
      lastName: "Jordan",
      password: "",
      bio: "Digital Copywriter At Digital Media Agency.I Love Sci-Fi Movies And Cats.",
      dob: "March - 7 - 1990",
      location: "London, UK",
      socialMedia: {
        facebook: "MichaelCJordan",
        linkedIn: "MichaelCJordan",
        instagram: "@MichaelCJordan",
        x: "@MichaelCJordan"
      },
      favoritePodcasts: [],
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      profilePicture: avatar_edit_state
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };
  
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  
  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start
  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.userEmail);
  }
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }))
  }
  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  handleAvatarClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if(file) {
      let imageUrl = URL.createObjectURL(file);
      this.setState({ profilePicture: imageUrl })
    }
  };
  parseDateString = (dateString: string): string => {
    const parts = dateString.split(' - '); 
    if (parts.length !== 3) return ''; 
    const month = parts[0]; 
    const day = parts[1];  
    const year = parts[2];  
    const dateObj = new Date(`${month} ${day}, ${year}`);
    return isNaN(dateObj.getTime()) ? '' : dateObj.toISOString().split('T')[0];
};

  // Customizable Area End
}
