// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    TextField,
    Button,
    IconButton,
    Dialog, DialogContent
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import EmailForgotPasswordController, {
    Props,
} from "./EmailForgotPasswordController";
import { ForgotPasswordImg, logoSvg, star } from "./assets";
import { Formik, Form } from "formik";
import { ConditionalRender } from "../../../blocks/utilities/src/ConditionalRender";

export default class EmailForgotPassword extends EmailForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }
    validationSchema = Yup.object().shape({        
    });
    render() {
        return (
            <>
                <StyleWrapperSocialLink>
                    <Grid className="container">
                        <Grid className="leftContainer">
                            <Formik
                                initialValues={{
                                    email_address : ""
                                }}
                                data-test-id="formikWrapper"
                                onSubmit={(values,{setErrors}) => {
                                    this.setState({ emailAddress: values.email_address }); 
                                    this.forgotPassword(values,setErrors)                                   
                                }}
                            >
                                {({ handleChange, handleSubmit, values ,touched,errors}) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Box className="logoImg">
                                            <img src={logoSvg} alt="logo" />
                                        </Box>
                                        <Box className="title">Forgot Password?</Box>
                                        <Box className="secTitle">Enter your email address below and we'll send you instructions to reset your password.</Box>

                                        <Grid className="fields">
                                            <Box>
                                                <label className="label">Enter your email address</label>
                                            </Box>
                                            <TextField
                                                name="email_address"
                                                id="outlined-basic"
                                                onChange={handleChange}                                              
                                                value={values.email_address}
                                                sx={{
                                                    width: "100%",
                                                    marginTop: "4%",
                                                    "& .MuiOutlinedInput-root": {
                                                        "&:hover fieldset": {
                                                            borderColor: "#CBD5E1",
                                                        },
                                                        "& fieldset": {
                                                          borderColor: "#CBD5E1",
                                                        },
                                                      "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                      },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                      color: "#CBD5E1",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                      color: "#CBD5E1",
                                                      backgroundColor: "black", 
                                                      "&:-webkit-autofill": {
                                                        backgroundColor: "black !important", 
                                                        WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                        WebkitTextFillColor: "#CBD5E1 !important", 
                                                      },
                                                    },
                                                  }}
                                            />
                                        </Grid>
                                        {ConditionalRender(errors,touched,'email_address' ,<div className="errors">{errors.email_address}</div>)}

                                        <Button type="submit" className="btnContinue" data-test-id="submitBtn">
                                            <b>Send Link</b>
                                        </Button>
                                        <Box className="login"><b>You do not have an account?   <a href="EmailAccountRegistration" style={{ textDecoration: "none", color: "#1D4ED8", cursor: "pointer" }}>Sign Up</a></b></Box>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                        <Grid className="rightContainer">
                            <img src={ForgotPasswordImg} alt="image_socialLinks" className="imageSignUp" />
                        </Grid>
                    </Grid>
                </StyleWrapperSocialLink>


                <Dialog
                    open={this.state.isModalOpen}
                    PaperProps={{
                        sx: {
                            borderRadius: "8px 8px 32px 8px",
                            backgroundColor: "transparent",
                            height: "337px",
                            width: "600px",
                            position: "relative",
                        },
                    }}
                >
                    <IconButton
                      onClick={this.handleClose}
                      data-test-id = "onCloseModal"
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <DialogContent
                        style={{ backgroundColor: "#44403C", color: "white", padding: 0 }}
                    >
                        <Box>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10%" }}>
                                <img src={star} alt="star" />
                            </Box>

                            <h2 style={{ textAlign: "center", fontFamily: "Nexa-Heavy", height: "20px" }}>Success!</h2>
                            <p style={{ textAlign: "center", fontFamily: "Nexa-Regular", textTransform: "capitalize", fontWeight: 1000, fontSize: "20px" }}>
                                A password reset link has been<br /> sent to your email.
                            </p>
                        </Box>
                        <Box style={{
                            width: "322px", height: "104px", display: "flex", margin: "auto", justifyContent: "space-between",
                            alignItems: "center",
                        }} id="loginModal">
                            
                                <Button 
                                data-test-id="resend_link"
                                onClick={()=>{
                                    this.handleResendLink()
                                }}
                                style={{
                                    height: "56px",
                                    width: "152px",
                                    borderRadius: "8px",
                                    backgroundColor: "#030303",
                                    textTransform: "capitalize",
                                    color: "white",
                                    fontWeight: 900,
                                    fontSize: "14px",
                                }}>Resend link</Button>                            
                            <a href="/">
                                <Button style={{
                                    height: "56px",
                                    width: "152px",
                                    borderRadius: "8px",
                                    backgroundColor: "#FF0807",
                                    textTransform: "capitalize",
                                    color: "white",
                                    fontWeight: 900,
                                    fontSize: "14px",
                                }}>Go to login</Button>
                            </a>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        );
    }

}
const StyleWrapperSocialLink = styled('div')({
    margin: "0 auto",
    height: "auto",
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .secTitle": {
        color: "white",
        fontFamily: "Nexa-Regular",
        fontSize: "13px",
        fontWeight: 100,
        textAlign: "center",
        textTransform: "capitalize",
        padding: "20px"
    },
    "& .login": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 400,
        fontSize: "13px",
        textAlign: "center",
        marginTop: "4%",
        textTransform: "capitalize"
    },
    "& .errors": {
        color: "red",
        fontFamily: "Nexa-Heavy",
        fontWeight: 400,
        fontSize: "12px",
        marginTop: "6px"
    },
    "& .leftContainer": {
        padding: "3% 10%",
        width: "45%",
    },
    "& .imageSignUp": {
        height: "auto",
        width: "100%",
        top: "20%",
    },
    "& .btnSkip": {
        height: "6%",
        width: "100%",
        background: "#312F2D",
        color: "white",
        borderRadius: "8px",
        fontFamily: "Nexa-Heavy",
        fontSize: "18px",
        fontWeight: 900,
        marginTop: "10%",
        textTransform: "capitalize",
        "&:hover": {
            color: "white",
            background: "#312F2D",
        },
    },
    "& .logoImg": {
        justifyContent: "center",
        marginTop: "10%",
        display: "flex",
    },
    "& .btnContinue": {
        width: "100%",
        color: "white",
        height: "56px",
        background: "#FF0807",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        borderRadius: "8px",
        fontSize: "18px",
        marginTop: "11%",
        marginBottom: "7%",
        textTransform: "capitalize",
        "&:hover": {
            color: "white",
            background: "#FF0807",
        },
    },
    "& .label": {
        color: "white",
        fontSize: "14px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
    },
    "& .container": {
        width: "100%",
        backgroundColor: "black",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItens: "center"
    },
    "& .title": {
        fontWeight: 900,
        fontSize: "20px",
        color: "white",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        marginBottom: "4%",
        marginTop: "7%",
    },
    "& .rightContainer": {
        width: "55%",
        display: "flex",
        paddingTop: "5%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    "& .fields": {
        marginTop: "4%"
    },
})
// Customizable Area End
