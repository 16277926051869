import React, { Component } from 'react';
import {
    Typography,
    Box,
    Card
  } from "@mui/material";
  import { addBtn, playBtn, heart } from '../assets'


interface AudioCardProps {
  imageUrl: string;
  selected: string;
  added: string;
  songName: string;
  artist: string;
}

class AudioCard extends Component<AudioCardProps> {
  constructor(props: AudioCardProps) {
    super(props);
  }

  render() {
    return (
      <Card sx={{...audioCardStyles.cardContainer}}>
        <Box sx={audioCardStyles.imageContainer}>
            <Box sx={{...audioCardStyles.followingText}}
            >
              <Box display={"flex"} gap={2} flexDirection={"column"} sx={{ justifySelf: "flex-end", fontWeight:"bold" }}>
                {
                  this.props.selected === "selected" ? 
                  <img
                      src={heart}
                      alt={"Image-1"}
                  /> : 
                  <img
                  src={addBtn}
                  alt={"Image-1"}
                  />
                }
                {
                  this.props.added === "added" ? 
                  <img
                  src={heart}
                  alt={"Image-1"}
                  /> :
                <img
                    src={addBtn}
                    alt={"Image-1"}
                />
                }
                </Box>
            </Box>
          <Box sx={{position:"absolute", left: "50%", top:"30%", transform: "translateX(-50%)" }}>
            <img src={playBtn} alt='playIcon'  width={"86px"} height={"86px"} />
          </Box>
        <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            >
            <img
              src={`${this.props.imageUrl}`}
              alt={"Audio Image"}
              width={"238px"}
            />
        </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} padding={"8px 32px"}>
            <Typography sx={{fontWeight:900, fontSize:"14px", color:"white"}}>
                {this.props.songName}
            </Typography>
            <Typography sx={{fontWeight:900, fontSize:"14px", color:"white"}}>
            {this.props.artist}
            </Typography>
        </Box>
      </Card>
    );
  }
}

export default AudioCard;


export const audioCardStyles = {
    cardContainer: {
        maxWidth: `238px`,
        height:"307px",
        display: "flex",
        flexDirection: "column",
        gap: "0.2rem",
        borderRadius:"16px",
        backgroundColor: "#44403C"
    },
    imageContainer: {
        height:"307px",
        maxWidth: `238px`,
        position: "relative",
    },
    followingText: {
        top: "15px",
        right: "15px",
        position: "absolute",
        color: "white",
        borderRadius: "8px",
        padding: "8px"
    }
}