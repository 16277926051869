// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation, ResolveCondition } from "../../utilities/src/CustomHelpers";
import moment from 'moment-timezone';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  selectedId: number | null,
}

export interface SS {
  id: any;
}

interface PodcastSecondScreenFormData {
  formattedShow: string,
  explicitLanguage: string,
  restriction: string,
  timezone: string,
  publishTime: string
}
export default class SetUpProfileSecondScreenControllerNoRss extends BlockComponent<
  Props,
  S,
  SS
> {

  abbrs: Set<string> = new Set();
  abbreviationsArray: string[] = [];
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      selectedId: null,   
    };

    const allTimezones = moment.tz.names();

    allTimezones.forEach((tz) => {
        const standardAbbr = moment.tz(tz).zoneAbbr(); 
        this.abbrs.add(standardAbbr);
        const daylightAbbr = moment.tz(tz).add(6, 'months').zoneAbbr();
        if (daylightAbbr !== standardAbbr) {
            this.abbrs.add(daylightAbbr);
        }
    });
    this.abbreviationsArray = [...this.abbrs];
  }

  handleBoxClick = (id:number) => {
    this.setState({ selectedId: id }); // update selected box ID
  };
  handleSubmitSecondScreen = async (values: PodcastSecondScreenFormData) => {
    let storageData = await getStorageData('noRssData');
    let noRssData = storageData ? JSON.parse(storageData) : {};
    noRssData = {...noRssData, ...values};
    noRssData['showHosting'] = ResolveCondition((this.state.selectedId === 1), 'solo', ResolveCondition((this.state.selectedId === 2), 'co_host', ''));      
    setStorageData("noRssData", JSON.stringify(noRssData));
    setStorageData("feedName", 'noRss');
    if(this.state.selectedId === 2){
      try {
        handleNavigation("SetUpProfileThirdScreenNoRss", this.props);
      } catch (error) {
        console.error("Error navigating:", error);
      }
    }
    else {
      try {
        handleNavigation("ChooseTemplate", this.props);
      } catch (error) {
        console.error("Error navigating:", error);
      }
    }
  }

}
// Customizable Area End
