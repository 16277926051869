import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ResolveCondition, handleNavigation } from "../../utilities/src/CustomHelpers";
import { FormikErrors } from "formik";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any;
  apiError:string;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableConfirmPasswordField : boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  checkTick : boolean;
  username: string
  day: string
  month: string
  year: string
  location: string
  gender: string
  race: string
  fullNumber: string,
  countryCode: string,
  contactNo: string,
  modal : boolean;
  setFormikError: (errors: FormikErrors<{ [field: string]: any }>) => void;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  getUsername: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data:{},
      apiError:"",
      passwordHelperText: "",
      enablePasswordField: true,
      enableConfirmPasswordField : true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      checkTick : true,
      username: "",
      day: "",
      month: "",
      year: "",
      location: "",
      gender: "",
      race: "",
      fullNumber: "",
      countryCode: "",
      contactNo: "",
      modal : false,
      setFormikError:()=>{}
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start   
    this.handleResForGetUsernameApi(from,message); 
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const data = await getStorageData("Registration", true);
  
    if (data) {
      this.setState({
        firstName: this.getValue(data.first_name),
        lastName: this.getValue(data.last_name),
        username: this.getValue(data.username),
        email: this.getValue(data.email_address),
        phone: "",
        day: this.getValue(data.day),
        month: this.getValue(data.month),
        year: this.getValue(data.year),
        location: this.getValue(data.location),
        gender: this.getValue(data.gender),
        password: this.getValue(data.password),
        reTypePassword: this.getValue(data.confirm_password),
        race: this.getValue(data.race),
        fullNumber: this.getValue(data.full_number),
        countryCode: this.getValue(data.country_code),
        contactNo: this.getValue(data.contact_no),
      });
    }
  }
  
  getValue(value: string) {
    return value || "";
  }

  postUsernameApi = (values:any, setErrors:(errors: FormikErrors<{ [field: string]: any }>) => void) =>{
    this.setState({
      setFormikError:setErrors
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody = {
      "username": values.username,
      "full_phone_number": values.full_number,
      "email": values.email_address
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUsername = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserName
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForGetUsernameApi = async (from: string, message: Message) => {
    if (this.getUsername === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                 
        if (responseJson.username == "Username is available" && responseJson.full_phone_number == "Phone Number Is Valid" && responseJson.email == "Email is available" ) {          
                  handleNavigation("EmailRegistrationSocialLinks",this.props)        
      }
      else{
        const errorState = {
          email: ResolveCondition(responseJson.email !== "Email is available", responseJson.email ,'') ,
          user_name: ResolveCondition(responseJson.username !== "Username is available", responseJson.username, ''),
          contact_number: ResolveCondition(responseJson.full_phone_number !== "Phone Number Is Valid" ,responseJson.full_phone_number,''),
        };
      
        const errorKeys: { [key: string]: keyof typeof errorState } = {
          email_address: 'email',
          username: 'user_name',
          contact_no: 'contact_number',
        };
      
        const formikErrors: { [key: string]: any } = {};
      
        for (const [key, value] of Object.entries(errorKeys)) {
          if (errorState[value]) {
            formikErrors[key] = errorState[value];
          }
        }
                
        this.state.setFormikError(formikErrors); 
      }
    }
  };
  
  isStringNullOrBlank() {
    return true;
  }

  handleClickShowPassword = () => {
    this.setState(prevState => ({
      enablePasswordField: !prevState.enablePasswordField
  }));
  }
  handleClickShowConfirmPassword = () => {
    this.setState(prevState => ({
      enableConfirmPasswordField: !prevState.enableConfirmPasswordField
  }))
  }
  handleTick = () => {
    this.setState(prevState => ({
      checkTick: !prevState.checkTick
  }))
  }

  openLink = (url: string) => {
    if (typeof window !== "undefined") {
      window.open(url, "_blank");
    }
  };
  // Customizable Area End
}
