import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { image2, image1, image3, prev, next, sponsored1, suggested1, suggested2, suggested3 } from "./assets";
import React from 'react'
export const configJSON = require("./config.js");


interface ArrowProps {
  onClick: () => void;
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  continueListeningPodcasts: any;
  placeholdersNeeded: number
  sponsoredPodcasts: any
  suggestedPodcasts: any
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  audioLastPlayedApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      continueListeningPodcasts: [],
      placeholdersNeeded: typeof window !== 'undefined' && window.innerWidth > 1520 ? 2 : 1,
      sponsoredPodcasts: [],
      suggestedPodcasts: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      continueListeningPodcasts: [
        {id: 1, src: image1, alt: "image1", episode: "Animation quotes -EP:12", name: "Ilumination", progress: 100}, 
        {id: 2, src: image2, alt: "image2", episode: "Solar Dog -EP:12", name: "Concerts", progress: 70},
        {id: 3, src: image3, alt: "image3", episode: "The Ultima Podcast - EP:4", name: "90's Television", progress: 20},
        {id: 4, src: image1, alt: "image1", episode: "Animation quotes -EP:12", name: "Ilumination", progress: 80}
      ],
      sponsoredPodcasts: [
        {id: 1, src: sponsored1, alt: "image1", episode: "Episode 12", name: "The true"}, 
        {id: 2, src: sponsored1, alt: "image2", episode: "Episode 12", name: "The true"},
      ],
      suggestedPodcasts: [
        {id: 1, src: suggested1, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false}, 
        {id: 2, src: suggested1, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
        {id: 3, src: suggested2, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true}, 
        {id: 4, src: suggested3, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
        {id: 5, src: suggested1, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true}, 
        {id: 6, src: suggested1, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
        {id: 7, src: suggested1, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false}, 
        {id: 8, src: suggested1, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
      ]
    })
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getEmptyPlaceholders() {
    return Array.from({ length: this.state.placeholdersNeeded }, (_, index) => ({
      id: `placeholder-${index + 1}`,
      src: '',
      alt: 'Empty screen',
      episode: '',
      name: '',
      progress: 0,
    }));
  }

  getContinueListeningSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0

    return(
       {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: React.createElement(this.nextContinueArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevContinueArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
        ]
       }
    )
  }

  getSponsoredSlickSettings() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  
    return {
      dots: false,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextSponsoredArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevSponsoredArrow, { onClick: () => {}, innerWidth }),
    };
  }

  getSuggestedSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return(
       {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: React.createElement(this.nextSuggestedArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevSuggestedArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1700, 
            settings: {
              slidesToShow: 5, 
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
        ]
       }
    )
  }

  prevSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props
  
    return (
      <img
        src={prev}
        onClick={onClick}
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-5%" : "-6%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }}
        alt="Previous Arrow"
      />
    );
  };

  nextSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return (
      <img
        src={next} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          right: innerWidth > 1440 ? "-4%" : "-6%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Next Arrow"
      />
    );
  }

  prevContinueArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img
        src={prev} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-4.5%" : "-6.5%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Previous Arrow"
      />
    )
  }

  nextContinueArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img 
        src={next} 
        onClick={onClick}
        style={{
          position: 'absolute',
          right: innerWidth > 1440 ? "-3.5%" : "-5.5%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1
        }} 
        alt="Next Arrow"
      />
    )
  }

  prevSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img
        src={prev} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-4.5%" : "-5.5%",
          top: innerWidth > 1440 ? "45%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Previous Arrow"
      />
    )
  }

  nextSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img 
        src={next} 
        onClick={onClick}
        style={{
          position: 'absolute',
          right: innerWidth > 1440 ? "-3.5%" : "-5.5%",
          top: innerWidth > 1440 ? "45%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1
        }} 
        alt="Next Arrow"
      />
    )
  }
  // Customizable Area End
}
