// Customizable Area Start
import React from "react";
import FooterController, {
  Props,
} from "./FooterController";
import { Box ,styled} from "@mui/material";
import { logo } from "./assets";
import { openLink } from "../../../blocks/utilities/src/OpenLink";

const aboutLink = "https://www.podcasttown.net/about-us";
const careersLink = "https://www.podcasttown.net/careers";
const contactLink = "https://www.podcasttown.net/contact-us";
const advertiseLink = "https://www.podcasttown.net/contact-us";
const homeLink = "https://www.podcasttown.net";
const featuresLink = "https://www.podcasttown.com/features";
const pricingLink = "https://www.podcasttown.com/pricing";
const supportLink = "https://podcasttown.zohodesk.com/portal/en/newticket";
const termsLink = "https://www.podcasttown.net/terms-of-service";
const privacyLink = "https://www.podcasttown.net/privacy-policy";
const residentCommunityLink = "https://www.podcasttown.net/community";
const hostCommunityLink = "https://www.podcasttown.net/community";
const mayorCircleLink = "https://www.podcasttown.net/the-mayors-circle";

export default class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <StyledWrapperFooter>
        <Box className="container">
          <Box className="leftContainer">
            <img
              src={logo}
              alt="logo"
              style={{ height: "92px", width: "211px" }}
            />
          </Box>
          <Box className="rightContainer">
            <Box className="rightSubContainer">
              <Box className="label">Company</Box>
              <Box className="label-sec" data-test-id="about" onClick={() => openLink(aboutLink)}>
                About
              </Box>
              <Box className="label-sec" data-test-id = "careersLink" onClick={() => openLink(careersLink)}>
                Careers
              </Box>
              <Box className="label-sec" data-test-id="contactLink" onClick={() => openLink(contactLink)}>
                Contact
              </Box>
              <Box className="label-sec" data-test-id="advertiseLink" onClick={() => openLink(advertiseLink)}>
                Advertise
              </Box>
            </Box>
            <Box
              className="rightSubContainer"
              style={{ width: "109px", height: "184px" }}
            >
              <Box className="label">Quick Links</Box>
              <Box className="label-sec" data-test-id="homeLink" onClick={() => openLink(homeLink)}>
                Home
              </Box>
              <Box className="label-sec" data-test-id="featuresLink" onClick={() => openLink(featuresLink)}>
                Features
              </Box>
              <Box className="label-sec" data-test-id="pricingLink" onClick={() => openLink(pricingLink)}>
                Pricing
              </Box>
              <Box className="label-sec" data-test-id="supportLink" onClick={() => openLink(supportLink)}>
                Support
              </Box>
            </Box>
            <Box
              className="rightSubContainer"
              style={{ width: "151px", height: "122px" }}
            >
              <Box className="label">Legal</Box>
              <Box className="label-sec" data-test-id="termsLink"  onClick={() => openLink(termsLink)}>
                Terms Of Service
              </Box>
              <Box className="label-sec" data-test-id="privacyLink" onClick={() => openLink(privacyLink)}>
                Privacy Policy
              </Box>
            </Box>
            <Box
              className="rightSubContainer"
              style={{ width: "193px", height: "161px" }}
            >
              <Box className="label">Community</Box>
              <Box
                className="label-sec"
                data-test-id="residentCommunityLink"
                onClick={() => openLink(residentCommunityLink)}
              >
                Resident Communities
              </Box>
              <Box
                className="label-sec"
                data-test-id="hostCommunityLink"
                onClick={() => openLink(hostCommunityLink)}
              >
                Host Communities
              </Box>
              <Box
                className="label-sec"
                data-test-id="mayorCircleLink"
                onClick={() => openLink(mayorCircleLink)}
              >
                The Mayor's Circle
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledWrapperFooter>
    );
  }
}

const StyledWrapperFooter = styled('div')({
margin :"auto",
width : "90%",
"& .container":{
height : "259px",
display : "flex",
},
"& .notextDecor":{
  textDecoration : "none",
  pointer : "cursor"
},
"& .rightSubContainer":{
  display : "flex",
  flexDirection : "column",
  marginRight : "50px",
  marginTop : "3%",
  width : "95px",
  height : "184px",  
},
"& .label-sec":{
  fontFamily : "Nexa-Regular",
  fontWeight : 900,
  fontSize : "16px",
  color : "white",
  cursor : "pointer"
},
"& .label":{
  color:"white",
  fontFamily : "Nexa-Heavy",
  fontWeight : 900,
  fontSize : "16px"
},
"& .leftContainer":{
  height : "100%",
  width : "45%",
  paddingRight : "12%",
  display:"flex",
  justifyContent:"center",
  alignItems : "center"
},
"& .rightContainer":{
  height : "100%",
  width : "55%",
  display : "flex",
  lineHeight : 2,
},
})


// Customizable Area End
