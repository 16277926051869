// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
    subscriptionTiers : string[]
}

export interface SS {
  id: any;
}

export default class HostMonetizationChooseTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
        subscriptionTiers : ["Access to Premium Episodes","Access to the Community","Ad-Free Experience"]
    };
  }

  handleItemClick = (item:any) => {
    const { subscriptionTiers } = this.state;
    const itemName = item;
  
    if (Array.isArray(subscriptionTiers) && subscriptionTiers.includes(itemName)) {
        this.setState({
            subscriptionTiers: subscriptionTiers.filter(
                (category) => category !== itemName
            ),
        });
    } else {
        this.setState({
            subscriptionTiers: Array.isArray(subscriptionTiers)
                ? [...subscriptionTiers, itemName]
                : [itemName],
        });
    }
  };


}
// Customizable Area End
