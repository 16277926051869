// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S { 
  isModalOpen : boolean
}

export interface SS {
  id: any;
}

export default class HostPaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {      
      isModalOpen : false
    };
  }
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
};
}
// Customizable Area End
