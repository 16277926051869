// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "framework/src/Utilities";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface CategoryStructure{
    "id": "8",
    "type": "category",
    "attributes": {
        "name": "science",
        "image": {
            "url": "/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6d2d6992001eb7f2f2028b8dd19912c9a5e62764/img.jpg"
        },
        "created_at": "2024-09-17T07:22:23.376Z",
        "updated_at": "2024-09-17T07:22:23.391Z"
    }
}

export interface SubCategoryStructure{
    "id": "1",
    "type": "sub_category",
    "attributes": {
        "id": 1,
        "name": "Pre Primary (kg)",
        "created_at": "2024-06-26T23:32:49.745Z",
        "updated_at": "2024-06-26T23:32:49.745Z",
        "categories": [],
        "image": null
    }
}

export interface S {
  files: unknown[],
  imageUrl: any,
  selectedFile: any,
}

export interface SS {
  id: any;
}

export default class NewContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  podcastCategoryApiCallId : string = "";
  podcastSubCategoryApiCallId : string = ";"

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      files: [],
      imageUrl: "",
      selectedFile: null,
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  }


 
  handleDrop = (event: React.DragEvent<HTMLDivElement>, setFieldValue: any) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };
  
  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };
  
  handleFile = (file: File, setFieldValue: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      this.setState({ 
        imageUrl: base64String,
        selectedFile: file 
      });  
      setFieldValue("podcastCover", base64String);  
    };
    reader.readAsDataURL(file);
  };

  clearFileSelection = (setFieldValue:any) => {
    this.setState({ selectedFile: null, imageUrl: null });
    setFieldValue("podcastCover", null); 
  };
  

  handleSubmit = async (values: any) => {
    let pageonedata = await values;
    setStorageData("NewContentData", JSON.stringify(pageonedata));
  }
 
}
// Customizable Area End
