// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  responseData : any;
  sponsoredContent : string[];  
  featureSponsor : string[]
}

export interface SS {
  id: any;
}

export default class HostMonetizationSponsoredContentPartnershipController extends BlockComponent<
  Props,
  S,
  SS
> {
  
    hostSponsoredFeatureApiCallId = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
        responseData : [],
   sponsoredContent : [],
   featureSponsor : ["SPONSOR/RECOMMEND CONTENT/CHANNELS ON YOUR CHANNEL","SPONSOR POSTS WITHIN YOUR COMMUNITY IN YOUR CHANNEL"]
    };
  }

  async receive(from: string, message: Message) {
    if (this.hostSponsoredFeatureApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForFetchSponsoredApi(message);
    }
  }

  async componentDidMount() {   
    this.hostSponsoredFeature()
  }

  hostSponsoredFeature =async () => {     
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
       
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hostSponsoredFeatureApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sponserFeature
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForFetchSponsoredApi = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      this.setState({ responseData: responseJson.data });
    }
  };

  handleItemClick = (item:any) => {
    const { featureSponsor } = this.state;
    const itemName = item?.attributes?.name;
  
    if (Array.isArray(featureSponsor) && featureSponsor.includes(itemName)) {
        this.setState({
            featureSponsor: featureSponsor.filter(
                (category) => category !== itemName
            ),
        });
    } else {
        this.setState({
            featureSponsor: Array.isArray(featureSponsor)
                ? [...featureSponsor, itemName]
                : [itemName],
        });
    }
  };

}
// Customizable Area End
