export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const rectangle45 = require("../assets/subscription.png").default
export const AccountsTopImage = require("../assets/AccountsTopImage.png").default;
export const artIcon = require("../assets/image_art.png").default;
export const personImage = require("../assets/image_person.png").default;
export const businessIcon = require("../assets/image_business_vector.png").default;
export const eductaionIcon = require("../assets/image_education.png").default;
export const comedyIcons = require("../assets/image_comedy.png").default;
export const goBack = require("../assets/goBack.svg").default;
export const topImageEditprofileack = require("../assets/topImageEditprofile.png").default;
export const avatar_edit_state = require("../assets/avatar_edit_state.png").default;

