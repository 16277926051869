import React from "react";
import {
  Container,
  Button, // Customizable Area Start
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography, // Customizable Area End
  Grid,
} from "@mui/material";

// Customizable Area Start
import { profile } from "./assets";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "@mui/styles";
import moment from "moment";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: 750,
      fontFamily: "Roboto-Medium",
    },
    h3: {
      fontWeight: 350,
      fontFamily: "Roboto-Medium",
    },
    h6: {
      fontWeight: 500,
      fontFamily: "Roboto-Medium",
    },
    subtitle1: {
      margin: "20px 0px",
      fontFamily: "Roboto-Medium",
    },
  },
});
import CommunityForumController, {
  Props,
  ICommunityObject,
} from "./CommunityForumController";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CommunityForum extends CommunityForumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start

    super.componentDidMount();
    this.alldata();

    // Customizable Area End
  }

  alldata = async () => {
    this.getAllUpadatedPost_Api();
  };

  renderPostFlatlistCell = (item: ICommunityObject, index: number) => {
    const timeSlot = moment(item && item.attributes?.created_at).fromNow();

    return (
      <Card key={index} style={webStyle.card}>
        <CardContent>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={profile} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6">
                    {item && item.attributes?.account?.attributes?.user_name}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {timeSlot}
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Grid container>
            <Grid item xs={12}>
              <Typography>{item && item.attributes?.name}</Typography>
              <Typography>{item && item.attributes?.description}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container>
            <Grid>
              <Typography variant="h3" align="center">
                Community Forum
              </Typography>
            </Grid>
            <Grid container>
              {this.state.postFlatlistItemSelect.map(
                (item: ICommunityObject, index: number) =>
                  this.renderPostFlatlistCell(item, index)
              )}

              <Button
                data-test-id="ButtonClick"
                {...this.gotoCreatePost}
                style={webStyle.fixbutton}
                startIcon={<AddCircleIcon />}
              />
            </Grid>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  card: {
    margin: 20,
    width: "100%",
    position: "relative" as "relative",
    left: 0,
  },

  giveaway: {
    width: "100%",
    height: "auto",
    padding: 0,
    margin: 0,
  },
  fixbutton: {
    bottom: 50,
    right: 50,
    position: "fixed" as "fixed",
    width: "100px",
    height: "100px",
    zIndex: 9999,
    backgroundColor: "#f6f6f6",
  },

  tag: {
    padding: 10,
  },
};
// Customizable Area End
