// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  isLinkValid: boolean | null,
  link: string,
  isValidated: boolean | null
}

export interface SS {
  id: any;
}

export default class HostSetupRssFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchRssFeedCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isLinkValid: null,
      link: '',
      isValidated: null
    };
  }

  async componentDidMount() {
    let isLinkValid = await getStorageData("linkValid");
    let linkValidity = isLinkValid === "true" ? true : isLinkValid === "false" ? false : null;
    this.setState({ isLinkValid: linkValidity });
  }

  openLink = (url: string) => {
    if (typeof window !== "undefined") {
      window.open(url, "_blank");
    }
  };

  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handlefetchRssFeedResponse(from,message);
    // Customizable Area End
  }
  fetchRssFeedApi = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
      "content-type": "application/json",
    };
    const body = {
      "rss_url": this.state.link
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchRssFeedCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchRss
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handlefetchRssFeedResponse = async (from: string, message: Message) => {
    if (this.fetchRssFeedCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson) {
          if (responseJson.error) {
            console.error('RSS Feed validation failed:', responseJson.message);
            this.setState({
              isValidated: false,
            });
          } else if (responseJson.feed) {
            this.setState({
              isValidated: true,
            });
          }
        } 
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const link = e.target.value;
    this.setState({ link: link, isValidated: null });
    this.fetchRssFeedApi();
  };
  handleContinue = async () => {
    let storageData = await getStorageData('rssData');
    let rssData = storageData ? JSON.parse(storageData) : {};
    rssData['link'] = this.state.link;
    setStorageData("rssData", JSON.stringify(rssData));
    setStorageData("feedName", "rss");
    setStorageData("linkValid", this.state.isValidated ? "true" : "false");
    try {
        handleNavigation("SetupRssFeedPreview", this.props);
    } catch (error) {
        console.error("Error navigating:", error);
    }
  }
}
// Customizable Area End
