import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  message: {          
    id: number;        
    sender: string;
    subject: string;   
    snippet: string;   
    time: string;      
    isRead: boolean; 
  }[];  
  selected: number[];
  openDialog: boolean; 
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyInboxController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  getMessagesApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      message: [],
      selected: [],
      openDialog: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getMessagesApi();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleGetMessagesApiResponse(from,message);
    // Customizable Area End
  }

  // Customizable Area Start
    getMessagesApi = async () => {     
    let token = await getStorageData("token")
    const header = {
      token : JSON.parse(token)
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMessagesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.messagesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleGetMessagesApiResponse = async (from: string, message: Message) => {
        if (this.getMessagesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            try {
                let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                console.log(responseJson, "responseJosn");
                
                responseJson = {
                    "data": [
                        {
                            "id": "1",
                            "type": "contact_host_message",
                            "attributes": {
                                "sender_id": 1353,
                                "receiver_id": 1353,
                                "name": "ARnie Jhonson",
                                "title": "Please call me",
                                "description": "Don forget call me to speak...",
                                "content": "This is a test message",
                                "phone_number": "1234567890"
                            }
                        },
                        {
                            "id": "2",
                            "type": "contact_host_message",
                            "attributes": {
                                "sender_id": 1353,
                                "content": "This is a test message",
                                "receiver_id": 1353,
                                "name": "Jack Mccaine",
                                "title": "Hi Michael :)",
                                "description": "Hi Michael  my name is Jack...",
                                "phone_number": "1234567890"
                            }
                        },
                        {
                          "id": "3",
                          "type": "contact_host_message",
                          "attributes": {
                              "sender_id": 1353,
                              "receiver_id": 1353,
                              "content": "This is a test message",
                              "title": "You forgot send the files",
                              "description": "Michael you don send me the...",
                              "phone_number": "1234567890",
                              "name": "Ana canal",
                          }
                      },
                      {
                        "id": "4",
                        "type": "contact_host_message",
                        "attributes": {
                            "receiver_id": 1353,
                            "content": "This is a test message",
                            "name": "Jhon Hoskins",
                            "sender_id": 1353,
                            "title": "you are the  intern?",
                            "description": "hi good morning sorry for asking...",
                            "phone_number": "1234567890"
                          }
                      },
                      {
                        "id": "5",
                        "type": "contact_host_message",
                        "attributes": {
                            "receiver_id": 1353,
                            "sender_id": 1353,
                            "content": "This is a test message",
                            "name": "jocelyn  jones",
                            "title": "You forgot send the files",
                            "description": "You forgot send the files",
                            "phone_number": "1234567890"
                        }
                    },
                    {
                      "id": "6",
                      "type": "contact_host_message",
                      "attributes": {
                          "sender_id": 1353,
                          "receiver_id": 1353,
                          "name": "jocelyn  jones",
                          "title": "You forgot send the files..",
                          "description": "You forgot send the files",
                          "phone_number": "1234567890",
                          "content": "This is a test message",
                      }
                  },
                  {
                    "id": "7",
                    "type": "contact_host_message",
                    "attributes": {
                        "phone_number": "1234567890",
                        "sender_id": 1353,
                        "receiver_id": 1353,
                        "content": "This is a test message",
                        "name": "jocelyn  jonesa",
                        "title": "You forgot send the files",
                        "description": "You forgot send the files",
                    }
                },
                    ]
                }
                if (responseJson) {
                    if(responseJson.data && responseJson.data.length > 0) {
                        let responseData: { id: number; sender: string; subject: string; snippet: string; time: string; isRead: boolean; }[] = [];
                        responseJson.data.forEach((item: any) => {
                            const attributes = item.attributes;
                            const data = {
                                id: parseInt(item.id), 
                                sender: attributes.name || '', 
                                subject: attributes.title || '', 
                                snippet: attributes.description || '', 
                                time: '20 hours ago', 
                                isRead: false 
                            };
                            
                            responseData.push(data);
                        });
                        this.setState({message: responseData})
                    }
                }
            } catch (error) {
                console.error('Error handling GET API response:', error);
            }
        }
    };
    handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, id);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1),
        );
        }
        this.setState({selected: newSelected});
    };
    handleReplyClick = (id: number) => {
        const messageForId = this.state.message.find(message => message.id === id);
        if (messageForId) {
            handleNavigation("ReplyTo", this.props)
        }
    }
    handleGoBack = () => {
      this.props.navigation.goBack()
    }
    handleOpenDialog = () => {
      this.setState({openDialog: true})
    }
    handleCloseDialog = () => {
      this.setState({openDialog: false})
    }
  // Customizable Area End
}
