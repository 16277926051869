import React, { Component } from 'react';
import {
    Typography,
    Box,
    Card
    // Customizable Area Start
    // Customizable Area End
  } from "@mui/material";

interface ImageCardProps {
  imageUrl: string;
  songName: string;
  artist: string;
  following: string
}

class ImageCard extends Component<ImageCardProps> {
  constructor(props: ImageCardProps) {
    super(props);
  }

  render() {
    return (
      <Card sx={{...imageCardStyles.cardContainer}}>
        <Box sx={imageCardStyles.imageContainer}>
        {this.props.following === "following" ? (
            <Box sx={{...imageCardStyles.followingText}}
            >
              <Box sx={{ justifySelf: "flex-end", fontWeight:"bold" }}>
                Following
              </Box>
            </Box>
          ) : (
            " "
          )}
        <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            >
            <img
              src={`${this.props.imageUrl}`}
              alt={"card image"}
              width={"238px"}
            />
        </div>
        </Box>
        <div            
        style={{
              display: "flex",
              flexDirection: "column",
              padding: "8px 32px"
            }} >
            <Typography sx={{fontWeight:900, fontSize:"14px", color:"white"}}>
                {this.props.songName}
            </Typography>
            <Typography sx={{fontWeight:900, fontSize:"14px", color:"white"}}>
            {this.props.artist}
            </Typography>
        </div>
      </Card>
    );
  }
}

export default ImageCard;


export const imageCardStyles = {
  cardContainer: {
      maxWidth: `238px`,
      height:"307px",
      display: "flex",
      flexDirection: "column",
      gap: "0.2rem",
      borderRadius:"16px",
      backgroundColor: "#44403C"
  },
  imageContainer: {
      height:"307px",
      maxWidth: `238px`,
      position: "relative",
  },
  followingText: {
      top: "15px",
      right: "15px",
      position: "absolute",
      background: "black",
      color: "white",
      borderRadius: "8px",
      padding: "8px"
  }
}